import { Divider, Stack } from "@mui/material";
import "./styles/Landing.module.css";
import { useEffect, useState } from "react";

function LandingPage() {
  const scrollLineLength = "50vh";
  const personalInformation = {
    name: "Shreyash Kumar Pandey",
    title: "Creative Developer",
    location: "Hyderabad, India",
    email: "",
    phone: "",
    socials: {
      linkedin: "https://www.linkedin.com/in/shreyash-pandey-katni",
      github: "https://github.com/shreyash-Pandey-Katni",
    },
    description: (
      <p className="page-header__lede t-primary lh-loose t-body:s t-sublede:m t-lede:l reveal-content">
        Meet a <b>Bengaluru</b>-based <b>software engineer</b> at{" "}
        <b>Software AG</b>, an <b>AI specialist</b> with a rich portfolio of{" "}
        <b>projects</b>, <b>certifications</b>, and a commendable{" "}
        <b>volunteering</b> record.
      </p>
    ),
    letsWorkTogether: {
      description: (
        <p>
          From mastering <b>AI algorithms</b> to developing scalable{" "}
          <b>knowledge graph embeddings</b>, from creating{" "}
          <b>single-page apps</b> to undertaking complex{" "}
          <b>machine learning projects</b>. I am your go-to{" "}
          <b>software engineer</b>. I assist incredible individuals in building
          ambitious yet comprehensible tech endeavors - the more challenging,
          the better.
        </p>
      ),
    },
    openSource: {
      description: (
        <p>
          In the spirit of contributing to the broader tech community, I often
          contribute to open-source projects. These projects, ranging from AI
          algorithms to knowledge graph embeddings, reflect my expertise and
          commitment to accessible knowledge sharing. Here are two of my most
          impactful contributions.
        </p>
      ),
      projects: [
        {
          name: "TorchKGE",
          iconBg: (
            <svg
              width="265"
              height="265"
              viewBox="0 0 265 265"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              fill="none"
              stroke="var(--fill)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.8"
            >
              <path
                fill="#C5173E"
                opacity="1.000000"
                stroke="none"
                d="
M174.188583,222.931808 
	C174.490540,223.405685 174.792480,223.879547 174.872406,224.847107 
	C175.516708,226.502686 176.383011,227.664551 177.249329,228.826431 
	C177.249329,228.826431 177.155685,228.896240 177.115387,229.277161 
	C177.196167,232.855347 176.546768,235.060791 172.487839,235.047714 
	C142.398453,234.950836 112.308189,235.055527 82.220032,234.840775 
	C80.464439,234.828247 78.515625,232.799606 77.035194,231.328354 
	C75.490234,229.792984 74.403580,227.796448 73.116562,226.001526 
	C73.020500,225.251556 72.924431,224.501602 73.195572,223.128738 
	C74.041954,220.168304 74.855583,217.838364 74.908852,215.491165 
	C74.965233,213.006500 76.228134,209.550262 71.818741,209.064987 
	C70.942131,208.021881 70.065521,206.978775 69.022469,205.300430 
	C66.556625,200.640198 63.548626,200.382355 59.999870,202.999329 
	C55.214741,202.986572 50.429615,202.973816 45.245609,202.426346 
	C44.184116,201.285233 43.576969,200.231842 42.850296,200.141647 
	C35.696892,199.253906 31.308283,195.009735 28.370922,188.769547 
	C27.871805,187.709229 26.805244,186.916000 26.000839,185.999390 
	C25.920538,185.244598 25.840240,184.489792 26.239851,183.273773 
	C27.132475,182.161057 27.693935,181.559998 27.934694,180.850189 
	C30.118351,174.412201 26.483433,168.893311 25.054523,163.023544 
	C25.082689,162.583145 25.110857,162.142746 25.193840,161.172028 
	C24.484451,158.093109 23.805458,155.513840 22.905695,153.014023 
	C22.624640,152.233154 21.747644,151.666794 21.143450,151.002228 
	C20.994961,149.376129 20.846470,147.750015 20.324081,145.538666 
	C15.986152,142.635803 12.022120,140.318207 8.058087,138.000595 
	C8.057635,137.218704 8.057184,136.436829 8.572979,135.225357 
	C9.307376,134.106659 9.525526,133.417526 9.743675,132.728409 
	C9.743675,132.728409 9.775554,132.842957 9.995468,132.735245 
	C10.316149,132.274353 10.416915,131.921188 10.517682,131.568008 
	C10.517682,131.568008 10.464240,131.477417 10.852960,131.573853 
	C14.846739,130.965179 14.594689,128.268768 14.379921,125.591599 
	C14.379921,125.591599 14.399948,125.461777 14.790909,125.553223 
	C18.621098,123.608170 21.044876,120.983322 20.425137,116.595085 
	C20.425137,116.595085 20.459703,116.510124 20.869829,116.599541 
	C22.131029,115.057747 22.982101,113.426529 23.833174,111.795319 
	C23.833174,111.795319 23.872112,111.911171 24.096535,111.766037 
	C24.531816,111.037521 24.742676,110.454140 24.953537,109.870766 
	C25.113033,109.344307 25.272530,108.817848 26.052204,108.077324 
	C31.127518,105.226433 28.792173,100.994736 28.763571,97.553467 
	C28.712351,91.391258 27.803915,85.239731 27.424620,79.073242 
	C27.360031,78.023201 28.246998,76.945671 28.524618,75.838295 
	C29.898628,70.357605 31.215651,64.862633 32.660492,59.435753 
	C32.768223,59.498810 32.556408,59.630951 32.743706,59.288017 
	C34.161480,57.179455 35.361794,55.391869 36.628208,53.652412 
	C40.080330,48.910843 43.557304,44.187347 47.030754,39.461338 
	C48.579453,37.354164 52.123837,36.190754 50.306580,32.500751 
	C50.306580,32.500751 50.417114,32.453117 50.776005,32.494411 
	C51.309086,32.207756 51.483276,31.879810 51.657467,31.551861 
	C51.657467,31.551861 51.575783,31.743656 51.971268,31.768305 
	C56.786446,30.893179 57.805191,30.052183 58.116402,27.046497 
	C58.546295,26.694525 58.976189,26.342554 59.921009,26.222601 
	C61.970509,26.129896 63.526001,25.881495 65.036148,25.467634 
	C72.813690,23.336174 80.603828,21.243757 88.332977,18.947268 
	C90.326439,18.354969 92.115524,17.074793 93.998489,16.110605 
	C94.750404,16.025320 95.502312,15.940036 96.806686,16.239353 
	C98.988213,17.349964 100.591736,18.620710 102.251221,18.698565 
	C106.992752,18.921017 111.760696,18.752995 116.511581,18.567600 
	C126.536049,18.176405 136.040070,19.984081 145.165543,24.302563 
	C147.790970,25.545012 151.032959,25.484634 153.992264,26.021587 
	C154.311478,25.953003 154.604935,26.012182 155.003128,26.763611 
	C159.853073,30.457926 164.572495,33.587761 169.291931,36.717594 
	C169.291931,36.717594 169.193954,36.780491 169.277832,37.005638 
	C169.702927,37.395573 170.044128,37.560356 170.385330,37.725140 
	C170.385330,37.725140 170.227127,37.757664 170.307678,37.973400 
	C170.735413,38.342304 171.082596,38.495468 171.429764,38.648636 
	C171.429764,38.648632 171.292618,38.690216 171.352371,38.922737 
	C171.761597,39.289215 172.111053,39.423172 172.460526,39.557133 
	C172.460526,39.557133 172.213776,39.845798 172.324646,39.990334 
	C172.780594,40.273670 173.125671,40.412472 173.470734,40.551277 
	C173.470734,40.551273 173.286545,40.844772 173.361450,40.989567 
	C173.781235,41.273293 174.126144,41.412224 174.471039,41.551155 
	C174.471039,41.551155 174.290817,41.844879 174.363525,41.989830 
	C174.781036,42.274529 175.125824,42.414284 175.470627,42.554035 
	C175.470627,42.554035 175.349045,42.843830 175.389984,42.994553 
	C175.769073,43.312313 176.107208,43.479359 176.445358,43.646404 
	C176.445358,43.646404 176.317886,43.711330 176.359680,43.940872 
	C176.734909,44.354717 177.068359,44.539021 177.401810,44.723324 
	C177.401810,44.723324 177.248810,44.772549 177.309784,44.985191 
	C177.691010,45.401333 178.011276,45.604828 178.331543,45.808327 
	C178.331543,45.808327 178.221481,45.803406 178.175583,46.141632 
	C181.094864,50.301567 184.060043,54.123280 187.025223,57.944992 
	C187.355804,58.424404 187.686386,58.903816 187.764191,59.941399 
	C187.519958,61.932262 187.264694,63.426228 187.580887,64.787468 
	C189.311844,72.239746 191.088135,79.685143 193.087082,87.068405 
	C193.481033,88.523560 194.918915,89.696083 195.875366,90.998947 
	C195.981155,92.717918 196.086960,94.436897 195.724472,96.678925 
	C194.175201,101.039986 193.058685,104.868423 192.020874,108.718063 
	C190.058655,115.996666 188.068573,123.269394 186.259888,130.586533 
	C185.834244,132.308502 186.083923,134.197388 186.022919,136.009491 
	C185.856796,136.580292 185.690674,137.151093 185.084534,137.731842 
	C184.060745,138.219513 183.273392,138.580597 182.923004,139.192078 
	C174.857513,153.267059 167.502579,167.263290 166.594543,184.323303 
	C165.953690,196.363464 167.254868,207.665436 170.610229,218.951492 
	C171.069504,220.496338 172.960480,221.615540 174.188583,222.931808 
M116.031761,43.040703 
	C116.031761,43.040703 116.040627,42.962002 115.524612,42.409729 
	C115.072426,42.625751 114.620232,42.841770 113.450310,43.016499 
	C112.971062,43.012962 112.491821,43.009430 111.945267,42.416145 
	C111.622047,41.945904 111.298828,41.475662 110.504051,41.368771 
	C110.401787,42.123756 110.299530,42.878742 109.882088,44.174507 
	C109.576248,44.454166 109.270409,44.733826 108.435440,44.920223 
	C107.988007,45.178131 107.540573,45.436035 106.590324,45.123184 
	C105.068687,45.083149 103.547043,45.043114 101.477821,44.553150 
	C101.097145,42.216476 100.278717,40.406872 96.803566,40.923870 
	C96.199631,41.286655 95.595695,41.649441 94.352554,42.001812 
	C93.563515,42.237797 92.774467,42.473778 91.197258,42.772430 
	C91.786926,43.211132 92.376602,43.649830 93.250992,44.720013 
	C94.157722,44.818913 95.064461,44.917816 96.098351,45.633942 
	C97.154427,46.285332 98.210510,46.936718 98.610153,47.840866 
	C97.741333,48.541382 96.872513,49.241898 95.548454,50.352348 
	C95.394196,50.729603 95.239944,51.106853 94.479607,51.859348 
	C92.374924,52.603134 90.270241,53.346920 87.327896,54.018970 
	C85.937042,54.388023 84.546188,54.757080 82.321846,55.024574 
	C80.927650,55.392956 79.533447,55.761337 77.319160,56.023911 
	C75.856049,56.329613 74.392944,56.635315 72.073647,56.982349 
	C70.096146,57.159767 68.118652,57.337181 65.690598,57.424316 
	C65.378960,57.504562 65.132042,57.677341 64.308151,58.005882 
	C63.908710,58.370506 63.509270,58.735130 62.258358,59.012562 
	C60.851421,59.059738 59.444489,59.106911 57.307884,59.235554 
	C55.887432,59.464588 54.466976,59.693623 52.951336,59.218636 
	C51.722836,55.290276 49.197296,57.712200 47.502480,58.426125 
	C46.176445,58.984711 45.334064,60.691463 44.643288,61.473270 
	C45.520081,62.562286 46.908768,64.287109 48.203453,66.909500 
	C47.856949,70.610947 47.510445,74.312401 47.046627,78.735756 
	C46.890839,79.687508 46.735050,80.639252 46.555401,81.736794 
	C44.932709,81.144180 43.500767,80.621239 42.068829,80.098289 
	C41.778824,80.528603 41.488815,80.958916 41.198811,81.389229 
	C44.795349,83.944489 48.391888,86.499748 52.106846,89.340866 
	C52.106846,89.340866 52.358181,89.521332 52.794964,90.044510 
	C53.436836,90.230103 54.078709,90.415695 54.720577,90.601288 
	C54.352657,89.807106 53.984741,89.012932 53.285568,87.808800 
	C52.863472,87.575066 52.441376,87.341331 51.375900,86.798378 
	C47.664928,84.690025 47.605843,81.539688 49.007378,77.165855 
	C49.269306,74.085770 49.531235,71.005692 49.921371,67.148094 
	C50.143360,65.072601 50.163597,62.975758 53.364311,64.602272 
	C54.227665,65.041008 55.713306,64.255180 57.460209,64.190536 
	C57.975674,63.465828 58.491142,62.741123 59.541294,61.659691 
	C59.795174,61.310249 60.049057,60.960804 60.749596,60.244942 
	C61.508442,59.833195 62.267288,59.421452 63.868832,59.063438 
	C66.863228,58.326359 69.857620,57.589279 73.666122,57.022930 
	C75.129883,56.694748 76.593651,56.366570 78.839630,56.061287 
	C80.252182,55.721001 81.664742,55.380718 83.858925,55.066654 
	C85.269005,54.713451 86.679085,54.360252 88.909721,54.073204 
	C91.292290,53.785889 93.674850,53.498569 96.302673,53.584053 
	C96.510170,53.805191 96.755852,53.969105 97.220810,54.345783 
	C97.220810,54.345783 97.654808,54.409840 97.654808,54.409840 
	C97.654808,54.409840 97.972588,54.061409 98.657646,54.009544 
	C99.114372,54.036915 99.571091,54.064285 100.398949,54.541454 
	C101.261505,54.551693 102.124062,54.561932 103.558037,54.164730 
	C105.172943,53.838619 106.787857,53.512508 108.996033,53.075535 
	C109.654671,52.636044 110.313316,52.196556 111.636642,52.081219 
	C112.473625,52.201534 113.846504,52.697746 114.065941,52.384899 
	C116.701729,48.627125 120.425674,50.319050 123.769005,50.332287 
	C125.502579,50.339149 127.234795,50.692009 129.563034,51.482079 
	C134.019424,51.651550 138.476791,51.938980 142.931473,51.902287 
	C143.963226,51.893787 144.985855,50.778496 146.827194,50.044075 
	C150.992264,48.796772 153.178024,52.092907 156.153580,54.551407 
	C156.209061,54.730900 156.264542,54.910397 156.111145,55.867584 
	C155.797363,58.247295 155.483566,60.627007 154.984161,63.661991 
	C154.097382,64.815323 153.210587,65.968658 151.576035,67.071068 
	C150.703049,67.417435 149.830063,67.763802 148.176529,68.024971 
	C146.087036,68.658646 144.035278,69.505981 141.900452,69.882988 
	C136.953690,70.756584 131.966782,71.402756 126.082352,71.981255 
	C122.055046,71.931343 118.027740,71.881432 113.893547,71.296181 
	C113.262268,71.477989 112.630989,71.659805 111.114578,72.003624 
	C103.079018,71.995644 95.043449,71.987663 87.081856,71.296654 
	C86.736519,69.996140 86.391182,68.695618 86.045845,67.395103 
	C85.355644,68.836082 84.665443,70.277061 83.149155,71.874580 
	C80.570442,72.286476 77.991730,72.698380 76.031212,73.011536 
	C77.082542,75.122040 78.047531,77.059219 78.476891,79.504082 
	C77.196640,83.677544 75.916389,87.851006 74.636139,92.024467 
	C75.028519,92.168602 75.420898,92.312737 75.813286,92.456879 
	C76.565910,90.587830 77.145561,88.622681 78.116592,86.874977 
	C79.236450,84.859428 80.701714,83.035797 82.561829,80.954010 
	C83.044151,80.653458 83.526474,80.352905 84.388184,80.450333 
	C84.872948,80.213089 85.357712,79.975845 85.842476,79.738602 
	C85.385277,79.159935 84.928078,78.581268 84.056786,77.304947 
	C85.691811,76.488327 87.326843,75.671707 89.423912,74.969681 
	C89.423912,74.969681 89.895958,75.031273 90.089699,75.511810 
	C90.382492,75.622185 90.675278,75.732567 91.872658,75.964294 
	C95.640686,75.412117 99.397057,74.752975 103.180298,74.340240 
	C106.442108,73.984375 109.734528,73.909119 113.730598,73.838966 
	C114.457008,73.790207 115.183418,73.741447 116.385452,73.891640 
	C116.703148,73.788208 117.020844,73.684776 117.924049,73.557991 
	C118.505928,73.491249 119.087799,73.424500 119.861328,73.752708 
	C120.315994,73.571045 120.770660,73.389381 122.031540,73.204590 
	C123.370262,73.183739 124.708977,73.162888 126.268875,73.561974 
	C126.488197,73.800629 126.751411,73.971817 127.666954,74.593414 
	C131.106705,74.725822 134.546463,74.858231 138.741562,74.996155 
	C139.496918,74.997025 140.252289,74.997894 141.007126,75.619576 
	C140.996750,76.033371 140.986374,76.447159 140.429520,77.252869 
	C140.000565,77.653229 139.571609,78.053589 139.142670,78.453941 
	C139.419098,78.854012 139.695526,79.254082 139.971954,79.654152 
	C142.661942,77.776703 145.351929,75.899254 148.715210,73.958069 
	C149.147247,73.853195 149.579300,73.748322 150.413651,73.691605 
	C150.413651,73.691605 150.817764,73.720993 151.006683,74.286110 
	C151.044327,74.481125 151.081955,74.676147 150.727371,75.397850 
	C151.125351,77.578766 151.523346,79.759682 152.133072,82.361549 
	C152.301514,82.643867 152.541199,82.838417 152.961700,83.710320 
	C152.875931,84.737244 152.790161,85.764175 152.345139,87.160500 
	C152.355103,87.814575 152.365067,88.468658 152.498337,89.248665 
	C152.498337,89.248665 152.660690,89.180031 152.369308,89.549416 
	C152.362442,90.323120 152.355576,91.096817 152.145203,92.498116 
	C151.812363,96.502197 152.497192,100.790825 148.160645,103.983826 
	C144.683731,103.909805 141.203552,103.907455 137.730530,103.748444 
	C126.836281,103.249649 115.944725,102.691841 105.021667,101.442322 
	C105.013969,100.965866 105.006271,100.489410 105.436081,99.579002 
	C103.965050,99.419907 102.494011,99.260811 100.736336,99.541458 
	C100.139290,99.428886 99.542252,99.316315 98.577423,98.615379 
	C97.692650,98.416878 96.807877,98.218376 95.498268,97.882530 
	C95.498268,97.882530 95.076035,98.027687 94.473145,97.828850 
	C92.338295,101.324120 88.930565,101.054855 85.490990,101.028000 
	C81.878479,100.999809 78.263542,101.283585 74.594894,101.375793 
	C74.594894,101.375793 74.544182,101.316803 74.802452,100.622704 
	C74.552322,99.749092 74.302193,98.875488 73.832031,97.703682 
	C73.832031,97.703682 73.872498,97.335312 74.135033,96.884010 
	C74.347397,96.314781 74.559761,95.745544 74.772125,95.176308 
	C73.907242,96.000969 73.042358,96.825623 72.169762,98.341423 
	C69.749130,99.086349 67.328491,99.831268 64.352699,100.747025 
	C64.042953,101.782616 70.672913,102.894722 64.068329,106.467354 
	C68.316872,106.467354 72.163101,107.616875 73.645645,106.233414 
	C76.961082,103.139587 80.296120,104.277885 83.769035,104.276329 
	C85.547028,104.275536 87.324928,104.101089 89.782242,104.001862 
	C90.234879,103.985802 90.687523,103.969742 91.806038,104.292252 
	C96.361862,105.227547 96.567711,108.308090 95.782265,112.049744 
	C95.415337,113.797661 95.472832,115.634682 95.355659,117.195488 
	C97.718590,112.577171 99.905846,108.302223 103.016106,103.979607 
	C111.188988,104.640251 119.355064,105.416939 127.538582,105.896065 
	C130.254242,106.055054 133.041016,105.081993 135.748688,105.276192 
	C140.177048,105.593819 144.567245,106.443672 149.374146,107.657318 
	C150.722900,110.249245 152.072800,110.799637 154.231476,108.202179 
	C155.611526,106.541603 157.656799,105.433876 160.010056,103.607918 
	C154.952728,104.517143 153.199829,102.373627 153.717636,97.479088 
	C153.633255,97.305969 153.548874,97.132858 153.659393,96.150688 
	C153.689926,94.768867 153.720459,93.387047 153.929321,91.268036 
	C153.903122,90.513451 153.876923,89.758873 154.015549,88.381790 
	C154.231689,87.568886 154.447830,86.755981 155.201355,85.723946 
	C155.484192,85.469154 155.767029,85.214363 156.570755,84.373680 
	C156.717438,81.912140 156.864120,79.450592 157.668839,77.002037 
	C158.107849,77.004799 158.546875,77.007553 159.358200,77.158646 
	C159.358200,77.158646 159.746902,77.257271 160.076340,77.785484 
	C160.368240,77.769859 160.660141,77.754227 160.952042,77.738602 
	C160.789032,77.344452 160.626022,76.950310 160.385254,76.280571 
	C160.385254,76.280571 160.341980,75.997505 160.894684,75.996307 
	C161.263123,75.998016 161.631577,75.999733 162.412689,75.768570 
	C162.662231,75.565796 162.854996,75.318733 163.236343,74.817398 
	C163.236343,74.817398 163.506180,74.639999 164.187256,74.720497 
	C165.091751,74.843201 165.996246,74.965897 167.292435,75.678101 
	C168.076996,75.678101 168.861542,75.678101 169.646103,75.678101 
	C169.644989,75.514114 169.643875,75.350136 169.642761,75.186150 
	C168.698792,75.186150 167.754837,75.186150 166.858734,74.456581 
	C165.295746,73.942604 163.732773,73.428619 161.824493,72.277420 
	C160.224243,71.836838 158.623993,71.396263 156.880554,70.421097 
	C156.780487,70.266113 156.680420,70.111130 156.584946,69.428932 
	C156.372818,68.984390 156.160675,68.539848 155.976730,68.039703 
	C155.976730,68.039703 155.914902,68.031731 155.928284,67.180649 
	C156.091156,65.775436 156.254028,64.370232 156.767426,62.346539 
	C157.082687,60.212826 157.397949,58.079113 157.900177,55.227406 
	C163.780930,48.212257 163.683594,47.235691 156.116684,42.999069 
	C154.224777,48.889217 149.160660,47.796993 144.596786,48.086975 
	C143.753693,48.140545 142.940323,48.662041 141.561737,48.978344 
	C141.377945,48.984127 141.194168,48.989906 140.988083,48.299732 
	C140.325089,47.539078 139.662094,46.778427 138.072906,46.009983 
	C134.048904,46.012276 130.024918,46.014568 125.277451,46.010185 
	C124.180618,46.612732 123.083786,47.215279 121.140640,47.944233 
	C119.432411,47.900230 117.724190,47.856228 116.117592,47.151093 
	C117.480171,45.206570 118.842743,43.262047 120.946716,40.259483 
	C118.763649,40.911602 117.895508,41.170933 116.406418,41.881062 
	C116.255135,42.267948 116.103859,42.654839 116.031761,43.040703 
M90.036430,87.360764 
	C89.920967,86.899620 89.805504,86.438477 89.690041,85.977333 
	C89.473320,86.062675 89.256592,86.148026 89.039864,86.233376 
	C89.341743,86.822136 89.643623,87.410904 90.059868,88.531174 
	C90.350677,88.841324 90.641487,89.151474 90.932289,89.461609 
	C91.108887,89.273315 91.285484,89.085022 91.462090,88.896729 
	C91.000534,88.566750 90.538986,88.236763 90.036430,87.360764 
M88.084732,42.581570 
	C88.664413,42.610054 89.244102,42.638542 89.823784,42.667030 
	C89.554947,42.379192 89.286110,42.091354 88.987373,41.198475 
	C88.614403,40.629879 88.241432,40.061287 87.868462,39.492691 
	C87.903648,40.307606 87.938828,41.122524 88.084732,42.581570 
M169.683945,102.733810 
	C170.784454,102.510780 171.884949,102.287750 173.232040,102.645020 
	C173.824493,102.837784 174.416931,103.030548 175.275482,103.562340 
	C175.485687,103.767982 175.732376,103.910461 176.802017,104.149620 
	C179.572357,102.841972 183.895294,103.364609 183.197601,96.965309 
	C179.323685,98.313354 175.583252,99.614944 171.160385,100.921051 
	C170.440308,101.282478 169.720245,101.643906 168.201965,101.994171 
	C166.970398,102.063225 165.738846,102.132278 164.507278,102.201332 
	C164.523315,102.475403 164.539352,102.749474 164.555374,103.023544 
	C166.039322,102.914299 167.523254,102.805061 169.683945,102.733810 
M111.694351,33.636860 
	C112.170586,31.314205 112.646828,28.991552 113.123062,26.668898 
	C112.764664,26.610933 112.406265,26.552969 112.047874,26.495003 
	C111.713966,28.966238 111.380058,31.437471 110.353493,34.201199 
	C110.263168,35.210140 110.172836,36.219082 110.082512,37.228024 
	C110.373741,37.237705 110.664970,37.247387 110.956200,37.257069 
	C110.956200,36.112160 110.956200,34.967247 111.694351,33.636860 
M136.575043,220.982513 
	C136.779831,220.971542 136.984604,220.960587 137.695358,221.404602 
	C141.644958,227.453201 152.878006,229.826996 160.002274,226.116882 
	C159.859985,225.856461 159.727539,225.374832 159.573990,225.368027 
	C155.948532,225.207031 152.315689,224.920273 148.694885,225.025253 
	C144.664383,225.142105 141.643570,223.916016 140.570816,219.989746 
	C140.570816,219.989746 141.022339,219.985275 141.561371,219.376556 
	C141.705093,218.411804 141.947510,217.450027 141.976578,216.481842 
	C142.171204,209.998978 142.158905,210.105560 148.893753,209.952057 
	C152.595184,209.867691 156.286774,209.351395 159.981003,208.446915 
	C159.974442,208.252731 159.967880,208.058533 160.120773,207.174164 
	C160.070847,206.482346 160.020920,205.790527 159.989929,204.533722 
	C159.987579,204.345291 159.985229,204.156876 159.358521,203.428192 
	C155.432022,203.287445 151.486099,202.841095 147.584290,203.088974 
	C143.398056,203.354904 141.362671,202.226166 141.914841,197.665298 
	C142.095764,196.171021 141.348785,194.564392 140.567581,192.997818 
	C140.567581,192.997818 140.109756,193.026733 140.119583,192.316513 
	C142.228745,189.393494 144.594025,188.169144 148.613846,188.802246 
	C152.352875,189.391129 156.377213,188.168411 160.973755,187.667160 
	C154.768799,181.436127 141.667725,184.405380 136.562042,192.008041 
	C136.360962,192.005600 136.159897,192.003159 135.423813,192.635468 
	C135.285095,198.384323 135.000229,204.134705 135.060043,209.881485 
	C135.098618,213.589417 135.642548,217.292114 136.575043,220.982513 
M112.851234,192.634445 
	C112.689835,192.380234 112.474533,192.185669 112.330017,191.381088 
	C114.237640,190.577316 116.092896,189.290543 118.064491,189.076767 
	C121.493271,188.704987 125.006447,189.166977 128.457413,188.913712 
	C129.631836,188.827515 130.725403,187.639648 131.855927,186.955170 
	C130.842331,186.104660 129.898285,184.646545 128.801712,184.521011 
	C125.866348,184.184982 122.821983,184.097900 119.899536,184.493164 
	C112.980324,185.428955 109.869026,188.523560 108.127563,194.791763 
	C107.752533,195.193588 107.054367,195.592422 107.050613,195.997696 
	C106.987656,202.792877 106.948143,209.590240 107.093651,216.383026 
	C107.112427,217.259338 108.189316,218.113007 108.961861,219.727112 
	C109.551025,224.033768 112.253914,226.005432 116.258286,227.285217 
	C121.816887,229.061722 128.652985,226.988892 131.058212,222.186813 
	C133.835648,216.641617 131.827255,207.332352 126.925690,204.824081 
	C125.205276,203.943726 122.775558,204.197556 120.714081,204.395447 
	C120.127052,204.451797 119.061897,206.456238 119.336975,206.995087 
	C119.787315,207.877258 121.260399,208.936615 122.120239,208.797470 
	C127.878334,207.865692 129.463715,210.350586 128.341202,215.623978 
	C128.000336,217.225357 128.273575,218.952332 127.985016,220.571808 
	C127.801445,221.602020 127.347641,222.832764 126.590935,223.469925 
	C122.950638,226.535004 115.169228,225.093353 112.578918,220.694138 
	C112.790451,220.490311 112.946098,220.250244 113.579826,219.347427 
	C113.718864,215.585968 114.046822,211.819672 113.950500,208.064255 
	C113.821838,203.047668 113.363899,198.039520 112.851234,192.634445 
M140.564133,176.363708 
	C140.708374,172.259811 141.114182,168.143555 140.920670,164.055634 
	C140.740082,160.240997 141.867126,158.611542 145.915634,158.900299 
	C148.242050,159.066238 150.637070,158.270508 152.964493,157.560532 
	C152.964493,157.560532 152.970978,157.211533 153.669907,157.397079 
	C155.519775,164.085403 157.369644,170.773743 159.204758,177.408722 
	C161.988968,173.823166 162.414795,166.223618 159.692032,160.191208 
	C155.409119,150.702164 146.426544,155.193665 139.660065,153.750427 
	C139.660065,148.083908 139.733749,142.489899 139.625443,136.899445 
	C139.579178,134.511932 139.414520,131.776672 135.024887,134.288361 
	C134.688629,134.993027 134.062698,135.696243 134.059174,136.402573 
	C133.996552,148.931763 133.966766,161.461731 134.087570,173.990021 
	C134.097244,174.993057 135.257828,175.985001 136.333908,177.197281 
	C137.136978,177.107956 137.940033,177.018631 139.380600,176.957382 
	C139.593109,176.966721 139.805603,176.976059 140.564133,176.363708 
M79.436516,186.633163 
	C79.319252,198.514954 79.189407,210.396683 79.123238,222.278763 
	C79.120049,222.850754 79.677940,223.425873 80.613586,223.973434 
	C80.826416,223.961090 81.039246,223.948730 81.674652,224.423279 
	C83.302200,228.038422 85.139977,226.872284 86.590797,224.432220 
	C88.111015,221.875427 89.099854,219.001770 90.630966,216.452835 
	C91.283325,215.366821 92.694313,214.154633 93.813370,214.100967 
	C94.849876,214.051239 96.327003,215.245590 96.959480,216.287155 
	C98.507072,218.835754 99.631065,221.637711 101.024521,224.285614 
	C101.608513,225.395340 102.443024,226.373230 103.163643,227.411072 
	C103.680908,227.123474 104.198174,226.835876 104.715431,226.548279 
	C102.423561,221.573715 100.375145,216.466461 97.759895,211.668152 
	C95.800056,208.072357 95.722839,204.996353 97.663956,201.388474 
	C100.270691,196.543472 102.403290,191.443359 104.739426,186.452759 
	C104.153664,186.124451 103.567902,185.796158 102.982140,185.467850 
	C101.804176,187.495682 100.499413,189.461380 99.470161,191.562088 
	C94.990349,200.705505 90.590736,209.888214 85.556206,218.460098 
	C85.369537,209.535202 85.233238,200.608734 84.955917,191.686661 
	C84.896759,189.783508 84.325058,187.896301 83.316490,185.831726 
	C82.633957,185.912430 81.951424,185.993118 80.626648,186.042984 
	C80.412575,186.031769 80.198494,186.020538 79.436516,186.633163 
M35.606964,137.555939 
	C43.004196,138.626984 43.004295,138.626984 43.004402,146.465668 
	C43.004517,154.907806 42.950993,163.350540 43.048515,171.791565 
	C43.068554,173.525955 43.652779,175.253815 44.611320,176.959839 
	C44.822735,176.950607 45.034145,176.941360 45.661774,177.018707 
	C46.475117,177.018661 47.288460,177.018600 48.853691,176.763290 
	C49.226368,176.071671 49.919453,175.382126 49.923904,174.688141 
	C49.996590,163.359634 50.020248,152.030212 49.914326,140.702316 
	C49.905960,139.808212 48.860508,138.923813 48.436886,137.276016 
	C51.882252,136.559189 55.327618,135.842361 58.772984,135.125519 
	C58.954620,134.763016 59.136257,134.400513 59.317898,134.038025 
	C58.025635,133.362442 56.753777,132.154114 55.437569,132.103683 
	C49.788612,131.887314 44.126160,131.970016 38.469574,132.031174 
	C36.026814,132.057587 34.075054,132.729767 35.008598,136.453644 
	C35.009281,136.642792 35.009968,136.831940 35.606964,137.555939 
M62.754105,39.721848 
	C65.779411,38.293949 68.804710,36.866047 71.603378,35.545116 
	C70.864815,34.598866 69.996796,33.017277 68.699532,31.964775 
	C67.542786,31.026279 65.731255,29.960676 64.540276,30.261955 
	C63.355576,30.561653 62.312935,32.374950 61.711014,33.745453 
	C61.163166,34.992840 61.290619,36.536812 60.673492,38.305416 
	C61.142426,38.860664 61.611362,39.415913 62.754105,39.721848 
M80.638550,162.102386 
	C79.436073,162.066635 78.233597,162.030884 76.693230,161.392731 
	C73.614449,154.116684 67.199585,153.705902 59.742207,155.295349 
	C55.131947,156.277969 51.225739,162.310501 52.246296,167.204285 
	C53.085777,171.229736 54.290207,175.702438 59.479877,176.765976 
	C69.188934,178.755692 73.364082,177.149109 77.652786,170.368332 
	C78.105125,171.803787 78.405449,173.316193 79.076279,174.641022 
	C79.394218,175.268921 80.420059,175.538406 81.598846,176.643753 
	C82.414886,176.173355 83.884430,175.765152 83.936142,175.221985 
	C84.187019,172.586960 84.055939,169.915710 84.063286,167.257294 
	C84.085365,159.262802 86.127831,157.253128 94.296898,157.205933 
	C96.955284,157.190567 99.616798,157.271530 102.270699,157.160522 
	C103.214905,157.121033 104.140778,156.643372 105.075012,156.365723 
	C105.029823,155.827362 104.984627,155.288986 104.939438,154.750610 
	C100.789185,154.750610 96.636681,154.837296 92.489243,154.729233 
	C86.991508,154.586014 83.276619,156.906204 80.638550,162.102386 
M115.895729,177.132263 
	C121.122375,177.132263 126.349014,177.132263 131.575653,177.132263 
	C131.557281,176.388321 131.538895,175.644363 131.520508,174.900421 
	C127.333138,174.900421 123.079765,175.344376 118.982964,174.735245 
	C116.493896,174.365158 113.086052,172.955063 112.097015,170.998230 
	C108.338356,163.561630 112.852303,157.155472 121.293556,157.140335 
	C124.715469,157.134186 128.137390,157.139313 131.559311,157.139313 
	C131.530960,156.449020 131.502609,155.758743 131.474258,155.068451 
	C124.937111,154.919830 118.246964,153.133270 111.957184,156.537201 
	C106.647873,159.410538 107.826668,164.792587 108.240967,169.387558 
	C108.582954,173.180588 110.406677,176.557465 115.895729,177.132263 
M109.297600,94.507523 
	C110.569229,95.007843 111.840851,95.508163 113.112480,96.008476 
	C113.241867,95.277771 113.371254,94.547058 113.500641,93.816353 
	C112.149086,93.816353 110.797539,93.816353 109.297600,94.507523 
M91.030373,65.183083 
	C91.001541,64.542091 90.972710,63.901096 90.943886,63.260101 
	C90.500481,63.360626 90.057068,63.461151 89.613663,63.561672 
	C89.904022,64.161003 90.194374,64.760338 91.030373,65.183083 
M171.890625,117.617538 
	C172.100922,117.991821 172.311218,118.366112 172.521515,118.740395 
	C172.677429,118.311485 172.957672,117.875458 172.933701,117.456848 
	C172.922684,117.264610 172.314651,117.106560 171.890625,117.617538 
M155.921814,118.158440 
	C155.805145,117.568115 155.688492,116.977791 155.571823,116.387474 
	C155.341370,116.453056 155.110901,116.518631 154.880432,116.584213 
	C155.066452,117.273186 155.252472,117.962151 155.921814,118.158440 
M174.985962,113.081116 
	C174.889587,112.532669 174.793213,111.984215 174.696838,111.435768 
	C174.484818,111.482353 174.272781,111.528938 174.060745,111.575531 
	C174.221603,112.238495 174.382462,112.901459 174.985962,113.081116 
M86.527176,85.851761 
	C86.457077,86.238983 86.386978,86.626213 86.316887,87.013435 
	C86.484596,86.718117 86.652313,86.422798 86.527176,85.851761 
M84.443878,43.487614 
	C84.443878,43.487614 84.522926,43.447163 84.443878,43.487614 
M174.554092,105.507179 
	C174.554092,105.507179 174.475616,105.548279 174.554092,105.507179 
M106.560249,96.495323 
	C106.560249,96.495323 106.477776,96.533340 106.560249,96.495323 
M157.444016,123.488129 
	C157.444016,123.488129 157.519531,123.449425 157.444016,123.488129 
M164.511063,76.444893 
	C164.511063,76.444893 164.554062,76.524460 164.511063,76.444893 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M73.076431,226.407990 
	C74.403580,227.796448 75.490234,229.792984 77.035194,231.328354 
	C78.515625,232.799606 80.464439,234.828247 82.220032,234.840775 
	C112.308189,235.055527 142.398453,234.950836 172.487839,235.047714 
	C176.546768,235.060791 177.196167,232.855347 177.128632,229.220291 
	C178.846176,231.355392 180.510193,233.928299 182.531631,237.053864 
	C146.239655,237.053864 110.867302,237.053864 75.216454,237.053864 
	C74.474014,233.566879 73.755165,230.190674 73.076431,226.407990 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M174.136719,222.554825 
	C172.960480,221.615540 171.069504,220.496338 170.610229,218.951492 
	C167.254868,207.665436 165.953690,196.363464 166.594543,184.323303 
	C167.502579,167.263290 174.857513,153.267059 182.923004,139.192078 
	C183.273392,138.580597 184.060745,138.219513 184.848129,137.922577 
	C182.738586,143.037872 180.631836,148.082077 178.077133,152.888168 
	C166.111755,175.398392 166.102676,198.545380 174.136719,222.554825 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M32.552765,59.372692 
	C31.215651,64.862633 29.898628,70.357605 28.524618,75.838295 
	C28.246998,76.945671 27.360031,78.023201 27.424620,79.073242 
	C27.803915,85.239731 28.712351,91.391258 28.763571,97.553467 
	C28.792173,100.994736 31.127518,105.226433 26.277504,107.911018 
	C25.934175,103.360840 26.461830,98.710030 25.957991,94.173775 
	C23.219860,69.521439 32.171909,49.518612 50.039154,32.659317 
	C52.123837,36.190754 48.579453,37.354164 47.030754,39.461338 
	C43.557304,44.187347 40.080330,48.910843 36.628208,53.652412 
	C35.361794,55.391869 34.161480,57.179455 32.707619,59.263687 
	C32.484230,59.582291 32.552765,59.372692 32.552765,59.372692 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M153.768036,25.759342 
	C151.032959,25.484634 147.790970,25.545012 145.165543,24.302563 
	C136.040070,19.984081 126.536049,18.176405 116.511581,18.567600 
	C111.760696,18.752995 106.992752,18.921017 102.251221,18.698565 
	C100.591736,18.620710 98.988213,17.349964 97.179688,16.313519 
	C116.773117,13.658602 135.759979,15.995389 153.768036,25.759342 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M186.277786,135.771942 
	C186.083923,134.197388 185.834244,132.308502 186.259888,130.586533 
	C188.068573,123.269394 190.058655,115.996666 192.020874,108.718063 
	C193.058685,104.868423 194.175201,101.039986 195.630096,97.100616 
	C196.922272,110.845970 192.268265,123.323105 186.277786,135.771942 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M93.564606,16.056316 
	C92.115524,17.074793 90.326439,18.354969 88.332977,18.947268 
	C80.603828,21.243757 72.813690,23.336174 65.036148,25.467634 
	C63.526001,25.881495 61.970509,26.129896 60.204765,26.197502 
	C70.094170,19.518847 81.361275,16.922482 93.564606,16.056316 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M195.924591,90.572594 
	C194.918915,89.696083 193.481033,88.523560 193.087082,87.068405 
	C191.088135,79.685143 189.311844,72.239746 187.580887,64.787468 
	C187.264694,63.426228 187.519958,61.932262 187.775970,60.240681 
	C190.684952,70.036613 193.329376,80.091423 195.924591,90.572594 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M25.949377,186.385315 
	C26.805244,186.916000 27.871805,187.709229 28.370922,188.769547 
	C31.308283,195.009735 35.696892,199.253906 42.850296,200.141647 
	C43.576969,200.231842 44.184116,201.285233 44.844337,202.317230 
	C34.191158,201.747482 28.092178,196.605469 25.949377,186.385315 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M24.714035,163.062927 
	C26.483433,168.893311 30.118351,174.412201 27.934694,180.850189 
	C27.693935,181.559998 27.132475,182.161057 26.352776,182.906754 
	C26.416767,177.609863 27.793247,172.106552 23.034443,167.570129 
	C22.524517,167.084030 23.882217,164.638733 24.714035,163.062927 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M8.018574,138.366623 
	C12.022120,140.318207 15.986152,142.635803 19.976242,145.314896 
	C12.845480,145.890060 10.287414,144.412704 8.018574,138.366623 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M71.844368,209.519318 
	C76.228134,209.550262 74.965233,213.006500 74.908852,215.491165 
	C74.855583,217.838364 74.041954,220.168304 73.270737,222.755936 
	C72.609138,218.661896 72.239571,214.317764 71.844368,209.519318 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M169.130249,36.424782 
	C164.572495,33.587761 159.853073,30.457926 155.136169,26.950954 
	C159.748657,29.759869 164.358612,32.945919 169.130249,36.424782 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M186.982452,57.600288 
	C184.060043,54.123280 181.094864,50.301567 178.204437,46.094711 
	C181.166016,49.558243 184.052856,53.406914 186.982452,57.600288 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M21.029354,151.381851 
	C21.747644,151.666794 22.624640,152.233154 22.905695,153.014023 
	C23.805458,155.513840 24.484451,158.093109 25.054440,160.860336 
	C19.656248,159.869263 19.257875,158.928360 21.029354,151.381851 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M20.185419,116.803726 
	C21.044876,120.983322 18.621098,123.608170 14.825979,125.497696 
	C16.295294,122.571274 18.120497,119.791817 20.185419,116.803726 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M60.343086,203.160492 
	C63.548626,200.382355 66.556625,200.640198 68.856766,205.055969 
	C66.133774,204.738388 63.410038,204.030014 60.343086,203.160492 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M57.777363,27.104481 
	C57.805191,30.052183 56.786446,30.893179 51.985970,31.665115 
	C53.549568,30.079006 55.493946,28.620735 57.777363,27.104481 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M14.151167,125.804375 
	C14.594689,128.268768 14.846739,130.965179 10.863677,131.522369 
	C11.631254,129.588684 12.776833,127.802917 14.151167,125.804375 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M23.551861,111.964600 
	C22.982101,113.426529 22.131029,115.057747 20.886292,116.556732 
	C21.418598,114.994301 22.344572,113.564095 23.551861,111.964600 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M177.183716,228.510040 
	C176.383011,227.664551 175.516708,226.502686 174.898178,225.137634 
	C175.803345,226.020859 176.460739,227.107254 177.183716,228.510040 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M9.451900,132.940094 
	C9.525526,133.417526 9.307376,134.106659 8.748476,134.982330 
	C8.658525,134.496521 8.909324,133.824142 9.451900,132.940094 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M24.704432,110.051331 
	C24.742676,110.454140 24.531816,111.037521 24.035614,111.770866 
	C23.985289,111.357857 24.220306,110.794876 24.704432,110.051331 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M177.309464,44.460812 
	C177.068359,44.539021 176.734909,44.354717 176.366562,43.869675 
	C176.626816,43.778728 176.921967,43.988514 177.309464,44.460812 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M51.417267,31.632267 
	C51.483276,31.879810 51.309086,32.207756 50.835732,32.501816 
	C50.750069,32.216175 50.963570,31.964426 51.417267,31.632267 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M178.245972,45.536194 
	C178.011276,45.604828 177.691010,45.401333 177.316910,44.905144 
	C177.562180,44.829655 177.861298,45.046860 178.245972,45.536194 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M176.350098,43.398628 
	C176.107208,43.479359 175.769073,43.312313 175.423645,42.841064 
	C175.695847,42.741520 175.975342,42.946182 176.350098,43.398628 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M175.362244,42.322113 
	C175.125824,42.414284 174.781036,42.274529 174.428711,41.830330 
	C174.698730,41.713985 174.976303,41.902088 175.362244,42.322113 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M174.362274,41.319763 
	C174.126144,41.412224 173.781235,41.273293 173.428802,40.829941 
	C173.698669,40.713139 173.976089,40.900753 174.362274,41.319763 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M173.361664,40.319733 
	C173.125671,40.412472 172.780594,40.273670 172.427078,39.830452 
	C172.696609,39.713417 172.974594,39.900803 173.361664,40.319733 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M10.293113,131.710266 
	C10.416915,131.921188 10.316149,132.274353 9.936876,132.725143 
	C9.795095,132.499344 9.931820,132.175949 10.293113,131.710266 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M171.315872,38.395447 
	C171.082596,38.495468 170.735413,38.342304 170.323273,37.894157 
	C170.572845,37.780205 170.887421,37.961231 171.315872,38.395447 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M172.342392,39.320938 
	C172.111053,39.423172 171.761597,39.289215 171.372437,38.853947 
	C171.629913,38.730003 171.927094,38.907372 172.342392,39.320938 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M170.274124,37.462727 
	C170.044128,37.560356 169.702927,37.395573 169.271927,36.947727 
	C169.509064,36.843216 169.835983,37.021763 170.274124,37.462727 
z"
              />
              <path
                fill="#F0D4D7"
                opacity="1.000000"
                stroke="none"
                d="
M108.720490,194.987610 
	C109.869026,188.523560 112.980324,185.428955 119.899536,184.493164 
	C122.821983,184.097900 125.866348,184.184982 128.801712,184.521011 
	C129.898285,184.646545 130.842331,186.104660 131.855927,186.955170 
	C130.725403,187.639648 129.631836,188.827515 128.457413,188.913712 
	C125.006447,189.166977 121.493271,188.704987 118.064491,189.076767 
	C116.092896,189.290543 114.237640,190.577316 112.114082,192.161057 
	C112.008133,202.276199 112.118118,211.611389 112.228104,220.946564 
	C115.169228,225.093353 122.950638,226.535004 126.590935,223.469925 
	C127.347641,222.832764 127.801445,221.602020 127.985016,220.571808 
	C128.273575,218.952332 128.000336,217.225357 128.341202,215.623978 
	C129.463715,210.350586 127.878334,207.865692 122.120239,208.797470 
	C121.260399,208.936615 119.787315,207.877258 119.336975,206.995087 
	C119.061897,206.456238 120.127052,204.451797 120.714081,204.395447 
	C122.775558,204.197556 125.205276,203.943726 126.925690,204.824081 
	C131.827255,207.332352 133.835648,216.641617 131.058212,222.186813 
	C128.652985,226.988892 121.816887,229.061722 116.258286,227.285217 
	C112.253914,226.005432 109.551025,224.033768 108.904037,218.886230 
	C108.804306,210.359436 108.762398,202.673523 108.720490,194.987610 
z"
              />
              <path
                fill="#F2D9DC"
                opacity="1.000000"
                stroke="none"
                d="
M86.161987,219.056656 
	C90.590736,209.888214 94.990349,200.705505 99.470161,191.562088 
	C100.499413,189.461380 101.804176,187.495682 102.982140,185.467850 
	C103.567902,185.796158 104.153664,186.124451 104.739426,186.452759 
	C102.403290,191.443359 100.270691,196.543472 97.663956,201.388474 
	C95.722839,204.996353 95.800056,208.072357 97.759895,211.668152 
	C100.375145,216.466461 102.423561,221.573715 104.715431,226.548279 
	C104.198174,226.835876 103.680908,227.123474 103.163643,227.411072 
	C102.443024,226.373230 101.608513,225.395340 101.024521,224.285614 
	C99.631065,221.637711 98.507072,218.835754 96.959480,216.287155 
	C96.327003,215.245590 94.849876,214.051239 93.813370,214.100967 
	C92.694313,214.154633 91.283325,215.366821 90.630966,216.452835 
	C89.099854,219.001770 88.111015,221.875427 86.590797,224.432220 
	C85.139977,226.872284 83.302200,228.038422 81.477882,223.715714 
	C81.277039,210.696701 81.272964,198.385269 81.268890,186.073822 
	C81.951424,185.993118 82.633957,185.912430 83.664566,186.385162 
	C84.012642,198.721344 84.012642,210.504120 84.012642,222.286880 
	C84.275909,222.331833 84.539177,222.376770 84.802444,222.421722 
	C85.255630,221.300034 85.708817,220.178345 86.161987,219.056656 
z"
              />
              <path
                fill="#F0D3D7"
                opacity="1.000000"
                stroke="none"
                d="
M138.743103,176.929321 
	C137.940033,177.018631 137.136978,177.107956 136.103561,176.622528 
	C135.833298,162.039917 135.793381,148.032043 135.753464,134.024170 
	C139.414520,131.776672 139.579178,134.511932 139.625443,136.899445 
	C139.733749,142.489899 139.660065,148.083908 139.660065,153.750427 
	C146.426544,155.193665 155.409119,150.702164 159.692032,160.191208 
	C162.414795,166.223618 161.988968,173.823166 159.204758,177.408722 
	C157.369644,170.773743 155.519775,164.085403 152.870102,157.171677 
	C147.680222,156.946274 143.290146,156.946274 138.743103,156.946274 
	C138.743103,164.006897 138.743103,170.468109 138.743103,176.929321 
z"
              />
              <path
                fill="#F3DEE1"
                opacity="1.000000"
                stroke="none"
                d="
M140.120071,219.963226 
	C141.643570,223.916016 144.664383,225.142105 148.694885,225.025253 
	C152.315689,224.920273 155.948532,225.207031 159.573990,225.368027 
	C159.727539,225.374832 159.859985,225.856461 160.001175,226.117493 
	C152.878006,229.826996 141.644958,227.453201 137.418655,220.712341 
	C137.149414,210.694077 137.156876,201.368073 137.164337,192.042068 
	C141.667725,184.405380 154.768799,181.436127 160.973755,187.667160 
	C156.377213,188.168411 152.352875,189.391129 148.613846,188.802246 
	C144.594025,188.169144 142.228745,189.393494 140.026581,193.127533 
	C139.933578,197.514771 139.933578,201.090958 139.933578,205.098709 
	C146.879105,205.098709 153.425049,205.098709 159.970993,205.098709 
	C160.020920,205.790527 160.070847,206.482346 159.575363,207.524612 
	C152.683319,207.875061 146.336685,207.875061 140.120071,207.875061 
	C140.120071,212.405838 140.120071,216.184525 140.120071,219.963226 
z"
              />
              <path
                fill="#F2DADD"
                opacity="1.000000"
                stroke="none"
                d="
M77.032578,170.011581 
	C73.364082,177.149109 69.188934,178.755692 59.479877,176.765976 
	C54.290207,175.702438 53.085777,171.229736 52.246296,167.204285 
	C51.225739,162.310501 55.131947,156.277969 59.742207,155.295349 
	C67.199585,153.705902 73.614449,154.116684 76.834259,162.138290 
	C76.994385,165.259766 77.013481,167.635666 77.032578,170.011581 
M61.850300,158.086914 
	C58.909710,158.776474 56.280682,159.210098 56.775696,163.630585 
	C57.767918,172.491043 57.503338,172.517975 66.689934,172.968643 
	C67.675598,173.016998 68.673553,173.268234 69.639931,173.170227 
	C72.411415,172.889206 74.450882,171.978790 73.404198,168.486816 
	C73.082085,167.412186 73.102684,166.191971 73.171112,165.050705 
	C73.439377,160.576538 71.312683,158.229446 66.707008,158.076767 
	C65.383949,158.032913 64.057983,158.076935 61.850300,158.086914 
z"
              />
              <path
                fill="#EFD3D6"
                opacity="1.000000"
                stroke="none"
                d="
M48.101799,177.018555 
	C47.288460,177.018600 46.475117,177.018661 45.453541,176.511765 
	C45.245308,162.676407 45.245308,149.347992 45.245308,135.886642 
	C41.315044,135.886642 38.172832,135.886642 35.030621,135.886642 
	C34.075054,132.729767 36.026814,132.057587 38.469574,132.031174 
	C44.126160,131.970016 49.788612,131.887314 55.437569,132.103683 
	C56.753777,132.154114 58.025635,133.362442 59.317898,134.038025 
	C59.136257,134.400513 58.954620,134.763016 58.772984,135.125519 
	C55.327618,135.842361 51.882252,136.559189 48.296524,138.116638 
	C48.138046,151.644363 48.119923,164.331467 48.101799,177.018555 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M115.446487,177.080963 
	C110.406677,176.557465 108.582954,173.180588 108.240967,169.387558 
	C107.826668,164.792587 106.647873,159.410538 111.957184,156.537201 
	C118.246964,153.133270 124.937111,154.919830 131.474258,155.068451 
	C131.502609,155.758743 131.530960,156.449020 131.559311,157.139313 
	C128.137390,157.139313 124.715469,157.134186 121.293556,157.140335 
	C112.852303,157.155472 108.338356,163.561630 112.097015,170.998230 
	C113.086052,172.955063 116.493896,174.365158 118.982964,174.735245 
	C123.079765,175.344376 127.333138,174.900421 131.520508,174.900421 
	C131.538895,175.644363 131.557281,176.388321 131.575653,177.132263 
	C126.349014,177.132263 121.122375,177.132263 115.446487,177.080963 
z"
              />
              <path
                fill="#F2DADD"
                opacity="1.000000"
                stroke="none"
                d="
M148.973541,107.069016 
	C144.567245,106.443672 140.177048,105.593819 135.748688,105.276192 
	C133.041016,105.081993 130.254242,106.055054 127.538582,105.896065 
	C119.355064,105.416939 111.188988,104.640251 102.293015,103.970650 
	C101.987305,102.969048 102.404678,101.976410 102.929825,101.253754 
	C103.709106,101.734787 104.380615,101.945847 105.052132,102.156891 
	C115.944725,102.691841 126.836281,103.249649 137.730530,103.748444 
	C141.203552,103.907455 144.683731,103.909805 148.559479,104.337372 
	C148.963394,105.483620 148.968460,106.276321 148.973541,107.069016 
z"
              />
              <path
                fill="#F5E3E5"
                opacity="1.000000"
                stroke="none"
                d="
M81.537109,162.198822 
	C83.276619,156.906204 86.991508,154.586014 92.489243,154.729233 
	C96.636681,154.837296 100.789185,154.750610 104.939438,154.750610 
	C104.984627,155.288986 105.029823,155.827362 105.075012,156.365723 
	C104.140778,156.643372 103.214905,157.121033 102.270699,157.160522 
	C99.616798,157.271530 96.955284,157.190567 94.296898,157.205933 
	C86.127831,157.253128 84.085365,159.262802 84.063286,167.257294 
	C84.055939,169.915710 84.187019,172.586960 83.936142,175.221985 
	C83.884430,175.765152 82.414886,176.173355 81.461044,175.854141 
	C81.394531,170.775955 81.465820,166.487396 81.537109,162.198822 
z"
              />
              <path
                fill="#F8ECED"
                opacity="1.000000"
                stroke="none"
                d="
M155.959183,54.022350 
	C153.178024,52.092907 150.992264,48.796772 146.078018,50.029480 
	C144.256973,49.665783 143.185104,49.316673 142.113235,48.967564 
	C142.940323,48.662041 143.753693,48.140545 144.596786,48.086975 
	C149.160660,47.796993 154.224777,48.889217 156.116684,42.999069 
	C163.683594,47.235691 163.780930,48.212257 157.674469,55.490768 
	C157.284653,55.606495 157.155319,55.432919 156.984680,54.940731 
	C156.592102,54.439487 156.275650,54.230919 155.959183,54.022350 
z"
              />
              <path
                fill="#F6E5E7"
                opacity="1.000000"
                stroke="none"
                d="
M73.872498,97.335312 
	C73.872498,97.335312 73.832031,97.703682 73.844139,98.215820 
	C74.085556,99.590912 74.314873,100.453857 74.544182,101.316803 
	C74.544182,101.316803 74.594894,101.375793 74.824509,101.765968 
	C79.737038,102.772469 84.419960,103.388802 89.102875,104.005127 
	C87.324928,104.101089 85.547028,104.275536 83.769035,104.276329 
	C80.296120,104.277885 76.961082,103.139587 73.645645,106.233414 
	C72.163101,107.616875 68.316872,106.467354 64.068329,106.467354 
	C70.672913,102.894722 64.042953,101.782616 64.352699,100.747025 
	C67.328491,99.831268 69.749130,99.086349 72.452408,97.923706 
	C73.114197,97.449097 73.493347,97.392204 73.872498,97.335312 
z"
              />
              <path
                fill="#C40537"
                opacity="1.000000"
                stroke="none"
                d="
M135.389175,134.156265 
	C135.793381,148.032043 135.833298,162.039917 135.878387,176.514954 
	C135.257828,175.985001 134.097244,174.993057 134.087570,173.990021 
	C133.966766,161.461731 133.996552,148.931763 134.059174,136.402573 
	C134.062698,135.696243 134.688629,134.993027 135.389175,134.156265 
z"
              />
              <path
                fill="#F9EEEF"
                opacity="1.000000"
                stroke="none"
                d="
M102.822052,100.983772 
	C102.404678,101.976410 101.987305,102.969048 101.831512,103.994492 
	C99.905846,108.302223 97.718590,112.577171 95.355659,117.195488 
	C95.472832,115.634682 95.415337,113.797661 95.782265,112.049744 
	C96.567711,108.308090 96.361862,105.227547 91.557755,103.791794 
	C92.564995,101.536789 93.820511,99.782242 95.076035,98.027687 
	C95.076035,98.027687 95.498268,97.882530 95.827560,98.292435 
	C97.086304,98.869476 98.015755,99.036613 98.945206,99.203751 
	C99.542252,99.316315 100.139290,99.428886 101.167870,99.334946 
	C102.098564,99.408417 102.597725,99.688393 103.026520,100.221771 
	C102.911453,100.644714 102.866760,100.814247 102.822052,100.983772 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M141.837494,48.972954 
	C143.185104,49.316673 144.256973,49.665783 145.670761,50.093693 
	C144.985855,50.778496 143.963226,51.893787 142.931473,51.902287 
	C138.476791,51.938980 134.019424,51.651550 129.371277,50.884884 
	C128.119629,49.473019 127.059761,48.658348 125.999954,47.539097 
	C126.000313,46.828629 126.000610,46.422745 126.000916,46.016861 
	C130.024918,46.014568 134.048904,46.012276 138.545288,46.360783 
	C139.681915,47.472950 140.346161,48.234318 141.010391,48.995682 
	C141.194168,48.989906 141.377945,48.984127 141.837494,48.972954 
z"
              />
              <path
                fill="#C40036"
                opacity="1.000000"
                stroke="none"
                d="
M48.477745,176.890930 
	C48.119923,164.331467 48.138046,151.644363 48.226601,138.496094 
	C48.860508,138.923813 49.905960,139.808212 49.914326,140.702316 
	C50.020248,152.030212 49.996590,163.359634 49.923904,174.688141 
	C49.919453,175.382126 49.226368,176.071671 48.477745,176.890930 
z"
              />
              <path
                fill="#F5E3E5"
                opacity="1.000000"
                stroke="none"
                d="
M87.007889,71.979683 
	C95.043449,71.987663 103.079018,71.995644 111.811111,71.996170 
	C112.674812,72.044617 112.841988,72.100525 113.010376,72.415329 
	C113.012199,73.019417 113.012810,73.364609 113.013420,73.709793 
	C109.734528,73.909119 106.442108,73.984375 103.180298,74.340240 
	C99.397057,74.752975 95.640686,75.412117 91.283951,75.718483 
	C90.464737,75.260498 90.198311,75.113365 89.895958,75.031273 
	C89.895958,75.031273 89.423912,74.969681 88.977806,74.699753 
	C87.682983,73.708031 86.834274,72.986244 86.253738,72.238480 
	C86.683914,72.134895 86.845901,72.057289 87.007889,71.979683 
z"
              />
              <path
                fill="#F9EFF0"
                opacity="1.000000"
                stroke="none"
                d="
M149.173843,107.363167 
	C148.968460,106.276321 148.963394,105.483620 148.957947,104.294586 
	C152.497192,100.790825 151.812363,96.502197 152.596741,92.229477 
	C153.282532,91.975632 153.516754,91.990433 153.750992,92.005226 
	C153.720459,93.387047 153.689926,94.768867 153.431885,96.691605 
	C153.395340,97.506271 153.586273,97.780022 153.777222,98.053772 
	C153.199829,102.373627 154.952728,104.517143 160.010056,103.607918 
	C157.656799,105.433876 155.611526,106.541603 154.231476,108.202179 
	C152.072800,110.799637 150.722900,110.249245 149.173843,107.363167 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M156.416901,62.965019 
	C156.254028,64.370232 156.091156,65.775436 155.580551,67.652657 
	C153.127731,69.424690 151.022659,70.724724 148.510406,71.998962 
	C146.746277,72.029350 145.389297,72.085541 143.623688,72.082947 
	C141.569672,72.110352 139.924255,72.196548 137.977997,72.185120 
	C137.098618,72.264893 136.520111,72.442284 135.500214,72.692299 
	C132.371170,72.787834 129.683548,72.810738 126.995979,72.660522 
	C126.996040,72.487396 126.996140,72.141136 126.996140,72.141136 
	C131.966782,71.402756 136.953690,70.756584 141.900452,69.882988 
	C144.035278,69.505981 146.087036,68.658646 148.709641,68.358490 
	C150.269760,68.168663 151.296783,67.645325 152.323792,67.121986 
	C153.210587,65.968658 154.097382,64.815323 155.388885,63.328117 
	C156.001373,62.984501 156.209137,62.974758 156.416901,62.965019 
z"
              />
              <path
                fill="#F6E5E7"
                opacity="1.000000"
                stroke="none"
                d="
M61.120403,37.950050 
	C61.290619,36.536812 61.163166,34.992840 61.711014,33.745453 
	C62.312935,32.374950 63.355576,30.561653 64.540276,30.261955 
	C65.731255,29.960676 67.542786,31.026279 68.699532,31.964775 
	C69.996796,33.017277 70.864815,34.598866 71.603378,35.545116 
	C68.804710,36.866047 65.779411,38.293949 62.381283,39.542423 
	C61.712444,38.892014 61.416424,38.421032 61.120403,37.950050 
z"
              />
              <path
                fill="#F9EDEE"
                opacity="1.000000"
                stroke="none"
                d="
M85.985565,72.264450 
	C86.834274,72.986244 87.682983,73.708031 88.746780,74.642456 
	C87.326843,75.671707 85.691811,76.488327 84.175140,77.994034 
	C84.198601,79.139526 84.103691,79.595932 84.008789,80.052345 
	C83.526474,80.352905 83.044151,80.653458 82.251358,80.679718 
	C80.964760,79.935745 79.988640,79.466072 79.012520,78.996391 
	C78.047531,77.059219 77.082542,75.122040 76.031212,73.011536 
	C77.991730,72.698380 80.570442,72.286476 83.897644,71.893967 
	C85.092606,72.030388 85.539085,72.147423 85.985565,72.264450 
z"
              />
              <path
                fill="#F8ECED"
                opacity="1.000000"
                stroke="none"
                d="
M56.913307,64.031448 
	C55.713306,64.255180 54.227665,65.041008 53.364311,64.602272 
	C50.163597,62.975758 50.143360,65.072601 49.675888,67.370499 
	C49.052750,67.065918 48.675102,66.538918 48.297455,66.011925 
	C46.908768,64.287109 45.520081,62.562286 44.643288,61.473270 
	C45.334064,60.691463 46.176445,58.984711 47.502480,58.426125 
	C49.197296,57.712200 51.722836,55.290276 52.964134,59.928162 
	C53.334507,61.416706 53.692081,62.195724 54.366699,63.002064 
	C55.104317,63.083603 55.524891,63.137817 56.031441,63.400841 
	C56.360523,63.792118 56.625820,63.932716 56.913307,64.031448 
z"
              />
              <path
                fill="#F6E6E8"
                opacity="1.000000"
                stroke="none"
                d="
M125.999886,47.843681 
	C127.059761,48.658348 128.119629,49.473019 129.073563,50.588123 
	C127.234795,50.692009 125.502579,50.339149 123.769005,50.332287 
	C120.425674,50.319050 116.701729,48.627125 114.065941,52.384899 
	C113.846504,52.697746 112.473625,52.201534 111.317062,51.508823 
	C111.619713,49.839668 112.241943,48.742905 113.159653,47.774609 
	C114.308739,47.872791 115.162354,47.842510 116.015961,47.812225 
	C117.724190,47.856228 119.432411,47.900230 121.960724,47.956223 
	C123.853836,47.926704 124.926857,47.885193 125.999886,47.843681 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M35.019608,136.170135 
	C38.172832,135.886642 41.315044,135.886642 45.245308,135.886642 
	C45.245308,149.347992 45.245308,162.676407 45.245434,176.468475 
	C45.034145,176.941360 44.822735,176.950607 44.301125,176.505478 
	C43.990929,163.222382 43.990929,150.393646 43.990929,137.021072 
	C40.540874,137.021072 37.775761,137.021072 35.010651,137.021072 
	C35.009968,136.831940 35.009281,136.642792 35.019608,136.170135 
z"
              />
              <path
                fill="#C40036"
                opacity="1.000000"
                stroke="none"
                d="
M108.424026,194.889679 
	C108.762398,202.673523 108.804306,210.359436 108.811134,218.511261 
	C108.189316,218.113007 107.112427,217.259338 107.093651,216.383026 
	C106.948143,209.590240 106.987656,202.792877 107.050613,195.997696 
	C107.054367,195.592422 107.752533,195.193588 108.424026,194.889679 
z"
              />
              <path
                fill="#FFFCFC"
                opacity="1.000000"
                stroke="none"
                d="
M112.864166,47.646141 
	C112.241943,48.742905 111.619713,49.839668 110.984718,51.346748 
	C110.313316,52.196556 109.654671,52.636044 108.324036,53.020042 
	C106.410851,52.652279 105.169655,52.340008 103.614120,51.974998 
	C102.882385,51.843006 102.464996,51.763748 102.198730,51.522560 
	C102.579994,50.985565 102.810135,50.610497 103.268860,50.116119 
	C103.644157,49.703053 103.790878,49.409298 104.098145,48.817112 
	C105.203499,47.577103 106.148315,46.635521 107.093132,45.693939 
	C107.540573,45.436035 107.988007,45.178131 108.752548,45.330238 
	C110.044655,45.488338 111.019653,45.236420 111.883163,45.078381 
	C111.771690,45.172256 111.889099,45.439041 111.969040,45.713001 
	C112.151077,46.147507 112.253174,46.308052 112.507660,46.630432 
	C112.819069,47.055157 112.887108,47.339779 112.864166,47.646141 
z"
              />
              <path
                fill="#F3DEE1"
                opacity="1.000000"
                stroke="none"
                d="
M175.009384,103.223305 
	C174.416931,103.030548 173.824493,102.837784 172.967972,102.118202 
	C172.416885,101.366440 172.129852,101.141487 171.842834,100.916534 
	C175.583252,99.614944 179.323685,98.313354 183.197601,96.965309 
	C183.895294,103.364609 179.572357,102.841972 176.352325,103.822037 
	C175.604874,103.404068 175.307129,103.313683 175.009384,103.223305 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M35.308807,137.288513 
	C37.775761,137.021072 40.540874,137.021072 43.990929,137.021072 
	C43.990929,150.393646 43.990929,163.222382 43.983940,176.517914 
	C43.652779,175.253815 43.068554,173.525955 43.048515,171.791565 
	C42.950993,163.350540 43.004517,154.907806 43.004402,146.465668 
	C43.004295,138.626984 43.004196,138.626984 35.308807,137.288513 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M81.087830,162.150604 
	C81.465820,166.487396 81.394531,170.775955 81.224220,175.517395 
	C80.420059,175.538406 79.394218,175.268921 79.076279,174.641022 
	C78.405449,173.316193 78.105125,171.803787 77.342682,170.189957 
	C77.013481,167.635666 76.994385,165.259766 77.003204,162.439484 
	C78.233597,162.030884 79.436073,162.066635 81.087830,162.150604 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M94.774590,97.928268 
	C93.820511,99.782242 92.564995,101.536789 91.224815,103.622513 
	C90.687523,103.969742 90.234879,103.985802 89.442558,104.003494 
	C84.419960,103.388802 79.737038,102.772469 74.851898,101.793587 
	C78.263542,101.283585 81.878479,100.999809 85.490990,101.028000 
	C88.930565,101.054855 92.338295,101.324120 94.774590,97.928268 
z"
              />
              <path
                fill="#C30124"
                opacity="1.000000"
                stroke="none"
                d="
M85.859100,218.758377 
	C85.708817,220.178345 85.255630,221.300034 84.802444,222.421722 
	C84.539177,222.376770 84.275909,222.331833 84.012642,222.286880 
	C84.012642,210.504120 84.012642,198.721344 84.001060,186.470154 
	C84.325058,187.896301 84.896759,189.783508 84.955917,191.686661 
	C85.233238,200.608734 85.369537,209.535202 85.859100,218.758377 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M79.974548,223.999527 
	C79.677940,223.425873 79.120049,222.850754 79.123238,222.278763 
	C79.189407,210.396683 79.319252,198.514954 79.716217,186.788788 
	C79.988800,199.296127 79.981674,211.647827 79.974548,223.999527 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M80.294067,223.986481 
	C79.981674,211.647827 79.988800,199.296127 79.990173,186.476868 
	C80.198494,186.020538 80.412575,186.031769 80.947769,186.058411 
	C81.272964,198.385269 81.277039,210.696701 81.266602,223.472260 
	C81.039246,223.948730 80.826416,223.961090 80.294067,223.986481 
z"
              />
              <path
                fill="#F4E0E2"
                opacity="1.000000"
                stroke="none"
                d="
M51.988426,89.055008 
	C48.391888,86.499748 44.795349,83.944489 41.198811,81.389229 
	C41.488815,80.958916 41.778824,80.528603 42.068829,80.098289 
	C43.500767,80.621239 44.932709,81.144180 46.555401,81.736794 
	C46.735050,80.639252 46.890839,79.687508 47.389435,78.370728 
	C48.111137,78.007835 48.490032,78.009972 48.868923,78.012100 
	C47.605843,81.539688 47.664928,84.690025 51.680992,87.277283 
	C51.986866,88.189133 51.987648,88.622070 51.988426,89.055008 
z"
              />
              <path
                fill="#E9C0C5"
                opacity="1.000000"
                stroke="none"
                d="
M78.744705,79.250237 
	C79.988640,79.466072 80.964760,79.935745 81.978928,80.767120 
	C80.701714,83.035797 79.236450,84.859428 78.116592,86.874977 
	C77.145561,88.622681 76.565910,90.587830 75.813286,92.456879 
	C75.420898,92.312737 75.028519,92.168602 74.636139,92.024467 
	C75.916389,87.851006 77.196640,83.677544 78.744705,79.250237 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M139.061859,176.943359 
	C138.743103,170.468109 138.743103,164.006897 138.743103,156.946274 
	C143.290146,156.946274 147.680222,156.946274 152.520630,157.078903 
	C152.970978,157.211533 152.964493,157.560532 152.517761,157.766602 
	C149.440582,157.983521 146.796844,158.165192 144.183044,157.961807 
	C140.946091,157.709930 139.809402,158.967880 139.948364,162.190994 
	C140.160629,167.114304 140.012955,172.053146 140.018097,176.985382 
	C139.805603,176.976059 139.593109,176.966721 139.061859,176.943359 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M140.345444,219.976486 
	C140.120071,216.184525 140.120071,212.405838 140.120071,207.875061 
	C146.336685,207.875061 152.683319,207.875061 159.495636,207.869690 
	C159.967880,208.058533 159.974442,208.252731 159.515564,208.728104 
	C156.751434,209.006516 154.452744,209.001526 152.154037,209.001343 
	C140.947372,209.000504 140.947372,209.000961 141.022339,219.985275 
	C141.022339,219.985275 140.570816,219.989746 140.345444,219.976486 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M140.291107,176.674545 
	C140.012955,172.053146 140.160629,167.114304 139.948364,162.190994 
	C139.809402,158.967880 140.946091,157.709930 144.183044,157.961807 
	C146.796844,158.165192 149.440582,157.983521 152.536133,157.940170 
	C150.637070,158.270508 148.242050,159.066238 145.915634,158.900299 
	C141.867126,158.611542 140.740082,160.240997 140.920670,164.055634 
	C141.114182,168.143555 140.708374,172.259811 140.291107,176.674545 
z"
              />
              <path
                fill="#FFFCFC"
                opacity="1.000000"
                stroke="none"
                d="
M148.917572,72.024757 
	C151.022659,70.724724 153.127731,69.424690 155.573853,68.078201 
	C155.914902,68.031731 155.976730,68.039703 155.956024,68.325645 
	C156.150314,69.059769 156.365341,69.507957 156.580353,69.956139 
	C156.680420,70.111130 156.780487,70.266113 156.904327,70.938507 
	C156.974167,71.783188 157.020218,72.110443 156.952728,72.831253 
	C156.180313,73.478310 155.521439,73.731804 154.691589,73.976791 
	C154.294891,73.920998 154.067505,73.910454 153.621887,73.563263 
	C152.600922,73.126007 151.796448,73.062134 150.785370,73.028336 
	C150.303497,73.169876 150.114349,73.364883 150.011337,73.643448 
	C149.579300,73.748322 149.147247,73.853195 148.093887,73.952316 
	C147.281281,73.953751 147.089981,73.960953 147.080261,73.722839 
	C147.813766,72.993271 148.365677,72.509010 148.917572,72.024757 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M159.980469,204.816223 
	C153.425049,205.098709 146.879105,205.098709 139.933578,205.098709 
	C139.933578,201.090958 139.933578,197.514771 140.021667,193.482651 
	C140.109756,193.026733 140.567581,192.997818 140.787994,193.463837 
	C141.006058,195.572830 141.183884,197.240128 140.966125,198.854019 
	C140.407837,202.991394 142.096695,204.366898 146.187515,204.072021 
	C150.764679,203.742065 155.382675,203.978500 159.982895,203.968460 
	C159.985229,204.156876 159.987579,204.345291 159.980469,204.816223 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M141.291855,219.680908 
	C140.947372,209.000961 140.947372,209.000504 152.154037,209.001343 
	C154.452744,209.001526 156.751434,209.006516 159.516479,209.019562 
	C156.286774,209.351395 152.595184,209.867691 148.893753,209.952057 
	C142.158905,210.105560 142.171204,209.998978 141.976578,216.481842 
	C141.947510,217.450027 141.705093,218.411804 141.291855,219.680908 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M159.670715,203.698334 
	C155.382675,203.978500 150.764679,203.742065 146.187515,204.072021 
	C142.096695,204.366898 140.407837,202.991394 140.966125,198.854019 
	C141.183884,197.240128 141.006058,195.572830 141.017288,193.469513 
	C141.348785,194.564392 142.095764,196.171021 141.914841,197.665298 
	C141.362671,202.226166 143.398056,203.354904 147.584290,203.088974 
	C151.486099,202.841095 155.432022,203.287445 159.670715,203.698334 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M112.403503,220.820343 
	C112.118118,211.611389 112.008133,202.276199 112.051743,192.495880 
	C112.474533,192.185669 112.689835,192.380234 112.932678,193.296265 
	C113.024704,202.630051 113.035278,211.302017 113.045853,219.973969 
	C112.946098,220.250244 112.790451,220.490311 112.403503,220.820343 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M135.959991,220.997147 
	C135.642548,217.292114 135.098618,213.589417 135.060043,209.881485 
	C135.000229,204.134705 135.285095,198.384323 135.704147,192.786453 
	C135.976318,202.290680 135.968155,211.643906 135.959991,220.997147 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M136.267517,220.989838 
	C135.968155,211.643906 135.976318,202.290680 135.971664,192.469086 
	C136.159897,192.003159 136.360962,192.005600 136.863190,192.025055 
	C137.156876,201.368073 137.149414,210.694077 137.165680,220.484848 
	C136.984604,220.960587 136.779831,220.971542 136.267517,220.989838 
z"
              />
              <path
                fill="#C52442"
                opacity="1.000000"
                stroke="none"
                d="
M113.312836,219.660706 
	C113.035278,211.302017 113.024704,202.630051 113.030746,193.492920 
	C113.363899,198.039520 113.821838,203.047668 113.950500,208.064255 
	C114.046822,211.819672 113.718864,215.585968 113.312836,219.660706 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M113.159653,47.774612 
	C112.887108,47.339779 112.819069,47.055157 112.832764,46.449181 
	C112.995232,45.718815 112.985008,45.331547 112.985481,44.972755 
	C112.996185,45.001232 112.935631,44.995255 113.343994,45.132103 
	C113.835587,44.823490 113.918823,44.378029 114.003212,43.963936 
	C114.004372,43.995304 113.943672,43.979275 114.196747,44.300941 
	C114.980080,44.069073 115.510353,43.515537 116.040627,42.962002 
	C116.040627,42.962002 116.031761,43.040703 116.354660,42.941803 
	C116.794159,42.372025 116.910767,41.901142 117.027367,41.430260 
	C117.895508,41.170933 118.763649,40.911602 120.946716,40.259483 
	C118.842743,43.262047 117.480171,45.206570 116.066772,47.481659 
	C115.162354,47.842510 114.308739,47.872791 113.159653,47.774612 
z"
              />
              <path
                fill="#F2DADD"
                opacity="1.000000"
                stroke="none"
                d="
M103.040283,50.235428 
	C102.810135,50.610497 102.579994,50.985565 101.922974,51.623234 
	C100.971718,52.110744 100.447357,52.335659 99.538223,52.635242 
	C98.121452,52.877026 97.089432,53.044136 96.057419,53.211250 
	C93.674850,53.498569 91.292290,53.785889 88.517380,54.060333 
	C88.125046,54.047462 88.165558,54.090710 88.165558,54.090710 
	C90.270241,53.346920 92.374924,52.603134 95.169937,51.592373 
	C97.356766,50.941017 98.853279,50.556637 100.684906,50.223717 
	C101.693443,50.261929 102.366859,50.248680 103.040283,50.235428 
z"
              />
              <path
                fill="#BB0009"
                opacity="1.000000"
                stroke="none"
                d="
M97.356537,41.130260 
	C100.278717,40.406872 101.097145,42.216476 101.737000,44.996513 
	C101.996170,45.439877 101.932495,45.872944 101.582870,45.926270 
	C99.887291,45.639099 98.541328,45.298599 96.962875,44.749603 
	C96.164330,44.283691 95.598274,44.026276 95.042831,43.617702 
	C95.053444,43.466541 95.032379,43.164215 95.347458,43.075405 
	C96.227203,42.367817 96.791870,41.749039 97.356537,41.130260 
z"
              />
              <path
                fill="#EFD3D6"
                opacity="1.000000"
                stroke="none"
                d="
M48.938148,77.588982 
	C48.490032,78.009972 48.111137,78.007835 47.448097,78.009781 
	C47.510445,74.312401 47.856949,70.610947 48.250454,66.460709 
	C48.675102,66.538918 49.052750,67.065918 49.611782,67.759262 
	C49.531235,71.005692 49.269306,74.085770 48.938148,77.588982 
z"
              />
              <path
                fill="#C51B3F"
                opacity="1.000000"
                stroke="none"
                d="
M138.278854,72.282745 
	C139.924255,72.196548 141.569672,72.110352 143.679840,72.394279 
	C144.520554,73.262016 144.896515,73.759628 145.102692,74.529541 
	C144.355804,75.081535 143.778702,75.361229 143.080200,75.356552 
	C142.308426,75.047714 141.658035,75.023239 141.007645,74.998764 
	C140.252289,74.997894 139.496918,74.997025 138.370941,74.764206 
	C138.000320,74.532265 138.012955,74.073624 138.031403,73.792801 
	C138.033951,73.325043 138.018066,73.138092 138.072159,72.784378 
	C138.142120,72.617607 138.278854,72.282753 138.278854,72.282745 
z"
              />
              <path
                fill="#F9EDEE"
                opacity="1.000000"
                stroke="none"
                d="
M126.539246,72.061195 
	C126.996140,72.141136 126.996040,72.487396 126.760300,72.741837 
	C126.524551,72.996284 126.047699,73.142029 126.047699,73.142029 
	C124.708977,73.162888 123.370262,73.183739 121.231667,73.163086 
	C120.177757,73.200310 119.923714,73.279030 119.669678,73.357750 
	C119.087799,73.424500 118.505928,73.491249 117.396431,73.443268 
	C116.549156,73.449921 116.229492,73.571304 115.909828,73.692688 
	C115.183418,73.741447 114.457008,73.790207 113.372009,73.774384 
	C113.012810,73.364609 113.012199,73.019417 113.258408,72.334763 
	C113.670288,71.940704 113.835358,71.886116 114.000435,71.831520 
	C118.027740,71.881432 122.055046,71.931343 126.539246,72.061195 
z"
              />
              <path
                fill="#F6E6E8"
                opacity="1.000000"
                stroke="none"
                d="
M101.932495,45.872944 
	C101.932495,45.872944 101.996170,45.439877 102.010788,45.221474 
	C103.547043,45.043114 105.068687,45.083149 106.841728,45.408562 
	C106.148315,46.635521 105.203499,47.577103 103.797028,48.717796 
	C102.573578,48.553509 101.811783,48.190113 101.167747,47.581688 
	C101.501160,46.848755 101.716827,46.360847 101.932495,45.872944 
z"
              />
              <path
                fill="#BC0000"
                opacity="1.000000"
                stroke="none"
                d="
M156.049866,84.959564 
	C155.767029,85.214363 155.484192,85.469154 154.906250,85.371964 
	C153.941360,83.422028 156.928314,80.521767 152.914459,79.927094 
	C152.950790,79.299400 152.942520,78.875275 153.302032,78.795975 
	C154.166977,78.109024 154.664139,77.077255 155.364227,76.035042 
	C155.567139,76.024597 155.973343,76.012657 155.981171,76.461159 
	C156.009277,79.592964 156.029572,82.276260 156.049866,84.959564 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M86.253738,72.238480 
	C85.539085,72.147423 85.092606,72.030388 84.310684,71.815704 
	C84.665443,70.277061 85.355644,68.836082 86.045845,67.395103 
	C86.391182,68.695618 86.736519,69.996140 87.044876,71.638168 
	C86.845901,72.057289 86.683914,72.134895 86.253738,72.238480 
z"
              />
              <path
                fill="#F2DADD"
                opacity="1.000000"
                stroke="none"
                d="
M143.201584,75.640923 
	C143.778702,75.361229 144.355804,75.081535 145.371490,74.461288 
	C146.172913,74.069878 146.535797,74.019012 146.898666,73.968155 
	C147.089981,73.960953 147.281281,73.953751 147.757233,73.984177 
	C145.351929,75.899254 142.661942,77.776703 139.971954,79.654152 
	C139.695526,79.254082 139.419098,78.854012 139.142670,78.453941 
	C139.571609,78.053589 140.000565,77.653229 140.957184,77.010399 
	C142.057098,76.392258 142.629333,76.016586 143.201584,75.640923 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M109.371796,94.161942 
	C110.797539,93.816353 112.149086,93.816353 113.500641,93.816353 
	C113.371254,94.547058 113.241867,95.277771 113.112480,96.008476 
	C111.840851,95.508163 110.569229,95.007843 109.371796,94.161942 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M156.592163,62.655777 
	C156.209137,62.974758 156.001373,62.984501 155.481689,63.000481 
	C155.483566,60.627007 155.797363,58.247295 156.400925,55.513901 
	C156.690704,55.160217 157.060791,55.233406 157.060791,55.233406 
	C157.155319,55.432919 157.284653,55.606495 157.580994,55.849766 
	C157.397949,58.079113 157.082687,60.212826 156.592163,62.655777 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M152.869843,80.130669 
	C156.928314,80.521767 153.941360,83.422028 154.637543,85.481529 
	C154.447830,86.755981 154.231689,87.568886 153.634399,88.728699 
	C153.055756,89.110413 152.858231,89.145218 152.660690,89.180031 
	C152.660690,89.180031 152.498337,89.248665 152.633408,88.826920 
	C152.747116,87.867157 152.725739,87.329124 152.704376,86.791100 
	C152.790161,85.764175 152.875931,84.737244 152.972565,82.861969 
	C152.945557,81.385963 152.907700,80.758316 152.869843,80.130669 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M126.158287,73.352005 
	C126.047699,73.142029 126.524551,72.996284 126.760239,72.914963 
	C129.683548,72.810738 132.371170,72.787834 135.829605,72.813904 
	C137.067673,72.892311 137.534927,72.921730 138.002197,72.951157 
	C138.018066,73.138092 138.033951,73.325043 137.572906,73.768646 
	C133.750137,74.042053 130.404327,74.058792 127.058510,74.075531 
	C126.751411,73.971817 126.488197,73.800629 126.158287,73.352005 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M152.914459,79.927094 
	C152.907700,80.758316 152.945557,81.385963 152.917755,82.479401 
	C152.541199,82.838417 152.301514,82.643867 152.054382,81.740707 
	C151.690323,79.036964 151.404968,76.954063 151.119598,74.871170 
	C151.081955,74.676147 151.044327,74.481125 150.961884,73.824295 
	C150.917084,73.362488 150.991974,72.998253 150.991974,72.998253 
	C151.796448,73.062134 152.600922,73.126007 153.395660,73.965836 
	C153.235352,75.978241 153.084793,77.214699 152.934235,78.451149 
	C152.942520,78.875275 152.950790,79.299400 152.914459,79.927094 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M72.852013,56.852196 
	C69.857620,57.589279 66.863228,58.326359 63.466064,59.060005 
	C63.063301,59.056576 63.109829,59.099751 63.109829,59.099751 
	C63.509270,58.735130 63.908710,58.370506 64.854919,57.999298 
	C65.731209,57.961773 65.977699,57.802402 66.141151,57.514599 
	C68.118652,57.337181 70.096146,57.159767 72.485397,56.939068 
	C72.897148,56.895786 72.852013,56.852196 72.852013,56.852196 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M127.362732,74.334473 
	C130.404327,74.058792 133.750137,74.042053 137.554459,74.049469 
	C138.012955,74.073624 138.000320,74.532265 137.993256,74.761459 
	C134.546463,74.858231 131.106705,74.725822 127.362732,74.334473 
z"
              />
              <path
                fill="#E5B6BB"
                opacity="1.000000"
                stroke="none"
                d="
M101.582870,45.926270 
	C101.716827,46.360847 101.501160,46.848755 100.856491,47.605713 
	C100.018425,47.824329 99.609352,47.773899 99.228249,47.695206 
	C99.256210,47.666939 99.266586,47.588104 99.266586,47.588104 
	C98.210510,46.936718 97.154427,46.285332 96.339813,45.288986 
	C96.785973,44.948719 96.990669,44.953411 97.195374,44.958103 
	C98.541328,45.298599 99.887291,45.639099 101.582870,45.926270 
z"
              />
              <path
                fill="#E9C0C5"
                opacity="1.000000"
                stroke="none"
                d="
M54.049656,62.974739 
	C53.692081,62.195724 53.334507,61.416706 53.011726,60.280174 
	C54.466976,59.693623 55.887432,59.464588 57.676777,59.462280 
	C58.042717,60.045532 58.039764,60.402050 57.727917,60.877808 
	C56.914375,61.303467 56.409729,61.609886 55.927757,61.799999 
	C55.950436,61.683689 55.723389,61.615753 55.402000,61.641090 
	C54.736961,62.102531 54.393307,62.538635 54.049656,62.974739 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M99.922989,52.560574 
	C100.447357,52.335659 100.971718,52.110744 101.771851,51.785160 
	C102.464996,51.763748 102.882385,51.843006 103.477921,52.318466 
	C103.432915,53.333836 103.209763,53.953003 102.986618,54.572174 
	C102.124062,54.561932 101.261505,54.551693 100.175262,53.934006 
	C99.942055,53.071236 99.932518,52.815907 99.922989,52.560574 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M156.310303,84.666626 
	C156.029572,82.276260 156.009277,79.592964 156.198349,76.572098 
	C156.675842,76.432404 156.876877,76.683907 157.010803,76.989052 
	C156.864120,79.450592 156.717438,81.912140 156.310303,84.666626 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M103.096886,99.968384 
	C102.597725,99.688393 102.098564,99.408417 101.311188,99.115082 
	C102.494011,99.260811 103.965050,99.419907 104.900566,99.782303 
	C103.942329,99.979858 103.519608,99.974121 103.096886,99.968384 
z"
              />
              <path
                fill="#F0D4D7"
                opacity="1.000000"
                stroke="none"
                d="
M157.066269,72.437706 
	C157.020218,72.110443 156.974167,71.783188 156.975922,71.205811 
	C158.623993,71.396263 160.224243,71.836838 161.652725,72.649406 
	C161.249634,73.043015 161.018311,73.064636 160.579041,73.124817 
	C160.371094,73.163361 159.956573,73.247566 159.663086,73.125473 
	C159.133850,73.447647 158.898117,73.891907 158.486969,74.512444 
	C158.311539,74.688721 157.961197,75.041794 157.571228,74.803192 
	C157.142929,73.855629 157.104599,73.146667 157.066269,72.437706 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M156.952728,72.831253 
	C157.104599,73.146667 157.142929,73.855629 157.647430,75.075027 
	C158.404358,76.060410 158.695129,76.535362 158.985886,77.010307 
	C158.546875,77.007553 158.107849,77.004799 157.339813,76.995544 
	C156.876877,76.683907 156.675842,76.432404 156.190521,76.123596 
	C155.973343,76.012657 155.567139,76.024597 155.308044,75.689041 
	C154.986832,74.897430 154.924713,74.441360 154.862579,73.985298 
	C155.521439,73.731804 156.180313,73.478310 156.952728,72.831253 
z"
              />
              <path
                fill="#F2DADD"
                opacity="1.000000"
                stroke="none"
                d="
M111.046150,33.908707 
	C111.380058,31.437471 111.713966,28.966238 112.047874,26.495003 
	C112.406265,26.552969 112.764664,26.610933 113.123062,26.668898 
	C112.646828,28.991552 112.170586,31.314205 111.349457,33.749428 
	C111.004570,33.861992 111.046150,33.908707 111.046150,33.908707 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M58.036812,60.758575 
	C58.039764,60.402050 58.042717,60.045532 58.041611,59.421547 
	C59.444489,59.106911 60.851421,59.059738 62.684093,59.056156 
	C63.109829,59.099751 63.063301,59.056576 63.044716,59.033142 
	C62.267288,59.421452 61.508442,59.833195 60.143852,60.449188 
	C59.037678,60.688484 58.537247,60.723530 58.036812,60.758575 
z"
              />
              <path
                fill="#F5E3E5"
                opacity="1.000000"
                stroke="none"
                d="
M160.786987,73.086273 
	C161.018311,73.064636 161.249634,73.043015 161.825378,72.968018 
	C163.732773,73.428619 165.295746,73.942604 166.859222,74.798782 
	C166.859726,75.140991 166.900742,75.088600 166.900742,75.088600 
	C165.996246,74.965897 165.091751,74.843201 163.620209,74.448532 
	C162.297775,73.813133 161.542374,73.449699 160.786987,73.086273 
z"
              />
              <path
                fill="#F6E6E8"
                opacity="1.000000"
                stroke="none"
                d="
M103.272324,54.368454 
	C103.209763,53.953003 103.432915,53.333836 103.792267,52.371201 
	C105.169655,52.340008 106.410851,52.652279 108.027405,53.075474 
	C106.787857,53.512508 105.172943,53.838619 103.272324,54.368454 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M150.923492,75.134506 
	C151.404968,76.954063 151.690323,79.036964 151.948517,81.530228 
	C151.523346,79.759682 151.125351,77.578766 150.923492,75.134506 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M125.999954,47.539097 
	C124.926857,47.885193 123.853836,47.926704 122.383881,47.893021 
	C123.083786,47.215279 124.180618,46.612732 125.639183,46.013523 
	C126.000610,46.422745 126.000313,46.828629 125.999954,47.539097 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M57.186760,64.110992 
	C56.625820,63.932716 56.360523,63.792118 56.005028,63.002686 
	C55.836227,62.135727 55.779808,61.875740 55.723389,61.615753 
	C55.723389,61.615753 55.950436,61.683689 56.315025,61.820671 
	C57.455280,61.977238 58.230946,61.996826 59.006607,62.016415 
	C58.491142,62.741123 57.975674,63.465828 57.186760,64.110992 
z"
              />
              <path
                fill="#C51B3F"
                opacity="1.000000"
                stroke="none"
                d="
M90.757553,65.271378 
	C90.194374,64.760338 89.904022,64.161003 89.613663,63.561672 
	C90.057068,63.461151 90.500481,63.360626 90.943886,63.260101 
	C90.972710,63.901096 91.001541,64.542091 90.757553,65.271378 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M74.003769,97.109665 
	C73.493347,97.392204 73.114197,97.449097 72.456253,97.578133 
	C73.042358,96.825623 73.907242,96.000969 74.772125,95.176308 
	C74.559761,95.745544 74.347397,96.314781 74.003769,97.109665 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M160.579041,73.124817 
	C161.542374,73.449699 162.297775,73.813133 163.279663,74.408279 
	C163.506180,74.639999 163.236343,74.817398 162.907104,75.039696 
	C162.302551,75.443840 162.109924,75.690315 162.000015,76.001434 
	C161.631577,75.999733 161.263123,75.998016 160.450287,75.852264 
	C160.005905,75.708221 159.934677,75.271713 159.940216,74.934296 
	C159.949371,74.147110 159.952972,73.697342 159.956573,73.247566 
	C159.956573,73.247566 160.371094,73.163361 160.579041,73.124817 
z"
              />
              <path
                fill="#F9EFF0"
                opacity="1.000000"
                stroke="none"
                d="
M171.501617,100.918793 
	C172.129852,101.141487 172.416885,101.366440 172.844681,101.828049 
	C171.884949,102.287750 170.784454,102.510780 169.346985,102.542160 
	C169.010010,102.350517 169.000168,102.005341 169.000168,102.005341 
	C169.720245,101.643906 170.440308,101.282478 171.501617,100.918793 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M84.198486,80.251343 
	C84.103691,79.595932 84.198601,79.139526 84.382187,78.342857 
	C84.928078,78.581268 85.385277,79.159935 85.842476,79.738602 
	C85.357712,79.975845 84.872948,80.213089 84.198486,80.251343 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M140.999237,48.647705 
	C140.346161,48.234318 139.681915,47.472950 139.008392,46.364677 
	C139.662094,46.778427 140.325089,47.539078 140.999237,48.647705 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M87.974014,41.937439 
	C87.938828,41.122524 87.903648,40.307606 87.868462,39.492691 
	C88.241432,40.061287 88.614403,40.629879 88.746643,41.574440 
	C88.328606,41.946079 88.151306,41.941761 87.974014,41.937439 
z"
              />
              <path
                fill="#F8ECED"
                opacity="1.000000"
                stroke="none"
                d="
M168.601074,101.999756 
	C169.000168,102.005341 169.010010,102.350517 169.008606,102.523163 
	C167.523254,102.805061 166.039322,102.914299 164.555374,103.023544 
	C164.539352,102.749474 164.523315,102.475403 164.507278,102.201332 
	C165.738846,102.132278 166.970398,102.063225 168.601074,101.999756 
z"
              />
              <path
                fill="#C5173D"
                opacity="1.000000"
                stroke="none"
                d="
M103.026520,100.221779 
	C103.519608,99.974121 103.942329,99.979858 104.681808,99.999283 
	C105.006271,100.489410 105.013969,100.965866 105.036896,101.799606 
	C104.380615,101.945847 103.709106,101.734787 102.929825,101.253754 
	C102.866760,100.814247 102.911453,100.644714 103.026520,100.221779 
z"
              />
              <path
                fill="#C62744"
                opacity="1.000000"
                stroke="none"
                d="
M97.080048,41.027065 
	C96.791870,41.749039 96.227203,42.367817 95.336929,42.786514 
	C95.004799,42.395031 94.998276,42.203629 94.991760,42.012230 
	C95.595695,41.649441 96.199631,41.286655 97.080048,41.027065 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M143.080200,75.356552 
	C142.629333,76.016586 142.057098,76.392258 141.230423,76.814438 
	C140.986374,76.447159 140.996750,76.033371 141.007385,75.309174 
	C141.658035,75.023239 142.308426,75.047714 143.080200,75.356552 
z"
              />
              <path
                fill="#F5E3E5"
                opacity="1.000000"
                stroke="none"
                d="
M96.962875,44.749603 
	C96.990669,44.953411 96.785973,44.948719 96.276230,44.980373 
	C95.064461,44.917816 94.157722,44.818913 93.122025,44.207726 
	C92.993050,43.695438 93.004585,43.301601 93.270493,43.304535 
	C94.035011,43.461269 94.533623,43.615067 95.032227,43.768864 
	C95.598274,44.026276 96.164330,44.283691 96.962875,44.749603 
z"
              />
              <path
                fill="#C52442"
                opacity="1.000000"
                stroke="none"
                d="
M96.003693,49.942417 
	C96.872513,49.241898 97.741333,48.541382 98.938370,47.714485 
	C99.266586,47.588104 99.256210,47.666939 99.173798,48.092316 
	C99.116135,49.046490 99.140877,49.575294 98.770927,50.074974 
	C97.585388,50.011372 96.794540,49.976894 96.003693,49.942417 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M95.776077,50.147385 
	C96.794540,49.976894 97.585388,50.011372 99.065247,50.100685 
	C99.952774,50.161106 100.151276,50.166683 100.349785,50.172256 
	C98.853279,50.556637 97.356766,50.941017 95.472977,51.404751 
	C95.239944,51.106853 95.394196,50.729603 95.776077,50.147385 
z"
              />
              <path
                fill="#F4E0E2"
                opacity="1.000000"
                stroke="none"
                d="
M83.155334,55.126129 
	C84.546188,54.757080 85.937042,54.388023 87.746727,54.054840 
	C88.165558,54.090710 88.125046,54.047462 88.107101,54.027256 
	C86.679085,54.360252 85.269005,54.713451 83.484756,55.075443 
	C83.110580,55.084232 83.155334,55.126129 83.155334,55.126129 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M78.139252,56.129719 
	C79.533447,55.761337 80.927650,55.392956 82.738586,55.075352 
	C83.155334,55.126129 83.110580,55.084232 83.093933,55.062332 
	C81.664742,55.380718 80.252182,55.721001 78.466484,56.074905 
	C78.093338,56.088520 78.139252,56.129719 78.139252,56.129719 
z"
              />
              <path
                fill="#F5E1E4"
                opacity="1.000000"
                stroke="none"
                d="
M78.075378,56.063454 
	C76.593651,56.366570 75.129883,56.694748 73.259064,56.937561 
	C72.852013,56.852196 72.897148,56.895786 72.913490,56.918404 
	C74.392944,56.635315 75.856049,56.329613 77.729202,56.076813 
	C78.139252,56.129719 78.093338,56.088520 78.075378,56.063454 
z"
              />
              <path
                fill="#C5193F"
                opacity="1.000000"
                stroke="none"
                d="
M151.949921,67.096527 
	C151.296783,67.645325 150.269760,68.168663 149.099915,68.401093 
	C149.830063,67.763802 150.703049,67.417435 151.949921,67.096527 
z"
              />
              <path
                fill="#C5193F"
                opacity="1.000000"
                stroke="none"
                d="
M113.946991,71.563850 
	C113.835358,71.886116 113.670288,71.940704 113.257187,72.075851 
	C112.841988,72.100525 112.674812,72.044617 112.253677,71.915169 
	C112.630989,71.659805 113.262268,71.477989 113.946991,71.563850 
z"
              />
              <path
                fill="#BB0009"
                opacity="1.000000"
                stroke="none"
                d="
M59.273949,61.838051 
	C58.230946,61.996826 57.455280,61.977238 56.292351,61.936981 
	C56.409729,61.609886 56.914375,61.303467 57.727917,60.877811 
	C58.537247,60.723530 59.037678,60.688484 59.920525,60.632401 
	C60.049057,60.960804 59.795174,61.310249 59.273949,61.838051 
z"
              />
              <path
                fill="#F6E5E7"
                opacity="1.000000"
                stroke="none"
                d="
M110.980385,33.842163 
	C110.956200,34.967247 110.956200,36.112160 110.956200,37.257069 
	C110.664970,37.247387 110.373741,37.237705 110.082512,37.228024 
	C110.172836,36.219082 110.263168,35.210140 110.699821,34.054955 
	C111.046150,33.908707 111.004570,33.861992 110.980385,33.842163 
z"
              />
              <path
                fill="#F8ECED"
                opacity="1.000000"
                stroke="none"
                d="
M152.514999,89.364723 
	C152.858231,89.145218 153.055756,89.110413 153.552002,89.039948 
	C153.876923,89.758873 153.903122,90.513451 153.840149,91.636627 
	C153.516754,91.990433 153.282532,91.975632 152.698502,91.915680 
	C152.355576,91.096817 152.362442,90.323120 152.514999,89.364723 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M99.538223,52.635242 
	C99.932518,52.815907 99.942055,53.071236 99.989700,53.709106 
	C99.571091,54.064285 99.114372,54.036915 98.081818,54.025425 
	C97.505997,54.041306 97.039703,54.075790 97.039703,54.075790 
	C96.755852,53.969105 96.510170,53.805191 96.180046,53.397652 
	C97.089432,53.044136 98.121452,52.877026 99.538223,52.635242 
z"
              />
              <path
                fill="#FFFCFC"
                opacity="1.000000"
                stroke="none"
                d="
M53.616821,88.218750 
	C53.984741,89.012932 54.352657,89.807106 54.720577,90.601288 
	C54.078709,90.415695 53.436836,90.230103 52.906723,89.472137 
	C53.217926,88.672752 53.417374,88.445747 53.616821,88.218750 
z"
              />
              <path
                fill="#C5163D"
                opacity="1.000000"
                stroke="none"
                d="
M90.077431,87.906784 
	C90.538986,88.236763 91.000534,88.566750 91.462082,88.896729 
	C91.285484,89.085022 91.108887,89.273315 90.932297,89.461609 
	C90.641487,89.151474 90.350677,88.841324 90.037872,88.245331 
	C90.015877,87.959496 90.077431,87.906784 90.077431,87.906784 
z"
              />
              <path
                fill="#C52442"
                opacity="1.000000"
                stroke="none"
                d="
M111.994644,44.984505 
	C111.019653,45.236420 110.044655,45.488338 109.017120,45.376869 
	C109.270409,44.733826 109.576248,44.454166 110.270264,44.036118 
	C110.829697,43.942211 111.000969,43.986702 111.363937,44.014252 
	C111.555626,43.997311 111.940254,43.983589 111.953552,44.233833 
	C111.976112,44.650887 111.985374,44.817696 111.994644,44.984505 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M60.896950,38.127731 
	C61.416424,38.421032 61.712444,38.892014 62.044380,39.667080 
	C61.611362,39.415913 61.142426,38.860664 60.896950,38.127731 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M95.042831,43.617702 
	C94.533623,43.615067 94.035011,43.461269 93.005035,43.174950 
	C92.310928,42.931541 92.148178,42.820652 91.985428,42.709759 
	C92.774467,42.473778 93.563515,42.237797 94.672157,42.007019 
	C94.998276,42.203629 95.004799,42.395031 95.021851,42.875328 
	C95.032379,43.164215 95.053444,43.466541 95.042831,43.617702 
z"
              />
              <path
                fill="#F6E5E7"
                opacity="1.000000"
                stroke="none"
                d="
M171.933990,117.275269 
	C172.314651,117.106560 172.922684,117.264610 172.933701,117.456848 
	C172.957672,117.875458 172.677429,118.311485 172.521515,118.740402 
	C172.311218,118.366112 172.100922,117.991821 171.933990,117.275269 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M155.680145,118.404778 
	C155.252472,117.962151 155.066452,117.273186 154.880432,116.584213 
	C155.110901,116.518631 155.341370,116.453056 155.571823,116.387474 
	C155.688492,116.977791 155.805145,117.568115 155.680145,118.404778 
z"
              />
              <path
                fill="#F0D4D7"
                opacity="1.000000"
                stroke="none"
                d="
M159.934677,75.271713 
	C159.934677,75.271713 160.005905,75.708221 160.173950,75.852859 
	C160.341980,75.997505 160.385254,76.280571 160.243561,76.592072 
	C159.983551,77.021461 159.865234,77.139366 159.746902,77.257271 
	C159.746902,77.257271 159.358200,77.158646 159.172043,77.084473 
	C158.695129,76.535362 158.404358,76.060410 158.037399,75.313629 
	C157.961197,75.041794 158.311539,74.688721 158.688461,74.685150 
	C159.355148,74.878296 159.644913,75.075005 159.934677,75.271713 
z"
              />
              <path
                fill="#F6E5E7"
                opacity="1.000000"
                stroke="none"
                d="
M174.764648,113.322769 
	C174.382462,112.901459 174.221603,112.238495 174.060745,111.575531 
	C174.272781,111.528938 174.484818,111.482353 174.696838,111.435768 
	C174.793213,111.984215 174.889587,112.532669 174.764648,113.322769 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M111.883163,45.078381 
	C111.985374,44.817696 111.976112,44.650887 111.974426,43.989685 
	C111.982018,43.495285 112.012573,43.005894 112.012573,43.005898 
	C112.491821,43.009430 112.971062,43.012962 113.757004,43.268478 
	C114.063690,43.520454 113.943672,43.979275 113.943672,43.979275 
	C113.943672,43.979275 114.004372,43.995304 113.727737,44.039154 
	C113.279282,44.387085 113.107452,44.691170 112.935631,44.995255 
	C112.935631,44.995255 112.996185,45.001232 112.666779,44.992348 
	C112.187958,45.135319 112.038528,45.287182 111.889099,45.439041 
	C111.889099,45.439041 111.771690,45.172256 111.883163,45.078381 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M98.761314,98.909561 
	C98.015755,99.036613 97.086304,98.869476 96.039978,98.361107 
	C96.807877,98.218376 97.692650,98.416878 98.761314,98.909561 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M74.673317,100.969757 
	C74.314873,100.453857 74.085556,99.590912 73.954147,98.364922 
	C74.302193,98.875488 74.552322,99.749092 74.673317,100.969757 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M111.172249,44.031197 
	C111.000969,43.986702 110.829697,43.942211 110.427856,43.765724 
	C110.299530,42.878742 110.401787,42.123756 110.752319,41.566048 
	C111.057808,42.519283 111.115028,43.275238 111.172249,44.031197 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M111.363937,44.014252 
	C111.115028,43.275238 111.057808,42.519283 110.988091,41.384373 
	C111.298828,41.475662 111.622047,41.945904 111.978920,42.711021 
	C112.012573,43.005894 111.982018,43.495285 111.961136,43.739437 
	C111.940254,43.983589 111.555626,43.997311 111.363937,44.014252 
z"
              />
              <path
                fill="#F5E3E5"
                opacity="1.000000"
                stroke="none"
                d="
M166.835297,75.163574 
	C167.754837,75.186150 168.698792,75.186150 169.642761,75.186150 
	C169.643875,75.350136 169.644989,75.514114 169.646103,75.678101 
	C168.861542,75.678101 168.076996,75.678101 167.096588,75.383347 
	C166.900742,75.088600 166.859726,75.140991 166.835297,75.163574 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M89.980690,87.979584 
	C89.643623,87.410904 89.341743,86.822136 89.039864,86.233376 
	C89.256592,86.148026 89.473320,86.062675 89.690041,85.977333 
	C89.805504,86.438477 89.920967,86.899620 90.056931,87.633774 
	C90.077431,87.906784 90.015877,87.959496 89.980690,87.979584 
z"
              />
              <path
                fill="#C41737"
                opacity="1.000000"
                stroke="none"
                d="
M86.673599,85.989624 
	C86.652313,86.422798 86.484596,86.718117 86.316887,87.013435 
	C86.386978,86.626213 86.457077,86.238983 86.673599,85.989624 
z"
              />
              <path
                fill="#DFA4AA"
                opacity="1.000000"
                stroke="none"
                d="
M119.765503,73.555229 
	C119.923714,73.279030 120.177757,73.200310 120.828560,73.164650 
	C120.770660,73.389381 120.315994,73.571045 119.765503,73.555229 
z"
              />
              <path
                fill="#F2D9DC"
                opacity="1.000000"
                stroke="none"
                d="
M114.196747,44.300941 
	C113.943672,43.979275 114.063690,43.520454 114.115868,43.289124 
	C114.620232,42.841770 115.072426,42.625751 115.782623,42.685867 
	C115.510353,43.515537 114.980080,44.069073 114.196747,44.300941 
z"
              />
              <path
                fill="#F4E0E2"
                opacity="1.000000"
                stroke="none"
                d="
M91.591339,42.741096 
	C92.148178,42.820652 92.310928,42.931541 92.739128,43.172012 
	C93.004585,43.301601 92.993050,43.695438 92.979660,43.891983 
	C92.376602,43.649830 91.786926,43.211132 91.591339,42.741096 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M156.582642,69.692535 
	C156.365341,69.507957 156.150314,69.059769 155.941925,68.353447 
	C156.160675,68.539848 156.372818,68.984390 156.582642,69.692535 
z"
              />
              <path
                fill="#F4E0E2"
                opacity="1.000000"
                stroke="none"
                d="
M88.029373,42.259506 
	C88.151306,41.941761 88.328606,41.946079 88.761597,41.876961 
	C89.286110,42.091354 89.554947,42.379192 89.823784,42.667030 
	C89.244102,42.638542 88.664413,42.610054 88.029373,42.259506 
z"
              />
              <path
                fill="#FFFCFC"
                opacity="1.000000"
                stroke="none"
                d="
M52.047638,89.197937 
	C51.987648,88.622070 51.986866,88.189133 52.002686,87.431900 
	C52.441376,87.341331 52.863472,87.575066 53.451195,88.013779 
	C53.417374,88.445747 53.217926,88.672752 52.688332,89.210541 
	C52.358181,89.521332 52.106846,89.340866 52.047638,89.197937 
z"
              />
              <path
                fill="#DB949D"
                opacity="1.000000"
                stroke="none"
                d="
M152.524750,86.975800 
	C152.725739,87.329124 152.747116,87.867157 152.571747,88.763962 
	C152.365067,88.468658 152.355103,87.814575 152.524750,86.975800 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M84.483398,43.467388 
	C84.522926,43.447163 84.443878,43.487614 84.483398,43.467388 
z"
              />
              <path
                fill="#F2D9DC"
                opacity="1.000000"
                stroke="none"
                d="
M116.716888,41.655663 
	C116.910767,41.901142 116.794159,42.372025 116.315063,42.942314 
	C116.103859,42.654839 116.255135,42.267948 116.716888,41.655663 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M174.514862,105.527725 
	C174.475616,105.548279 174.554092,105.507179 174.514862,105.527725 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M162.206360,75.885002 
	C162.109924,75.690315 162.302551,75.443840 162.784424,75.144684 
	C162.854996,75.318733 162.662231,75.565796 162.206360,75.885002 
z"
              />
              <path
                fill="#C62845"
                opacity="1.000000"
                stroke="none"
                d="
M175.142426,103.392822 
	C175.307129,103.313683 175.604874,103.404068 175.959076,103.742111 
	C175.732376,103.910461 175.485687,103.767982 175.142426,103.392822 
z"
              />
              <path
                fill="#E5B6BB"
                opacity="1.000000"
                stroke="none"
                d="
M153.747437,97.766434 
	C153.586273,97.780022 153.395340,97.506271 153.334442,97.096130 
	C153.548874,97.132858 153.633255,97.305969 153.747437,97.766434 
z"
              />
              <path
                fill="#C2182D"
                opacity="1.000000"
                stroke="none"
                d="
M106.519012,96.514328 
	C106.477776,96.533340 106.560249,96.495323 106.519012,96.514328 
z"
              />
              <path
                fill="#C6163E"
                opacity="1.000000"
                stroke="none"
                d="
M157.481781,123.468781 
	C157.519531,123.449425 157.444016,123.488129 157.481781,123.468781 
z"
              />
              <path
                fill="#F0D4D7"
                opacity="1.000000"
                stroke="none"
                d="
M160.282440,76.729858 
	C160.626022,76.950310 160.789032,77.344452 160.952042,77.738602 
	C160.660141,77.754227 160.368240,77.769859 159.911621,77.521378 
	C159.865234,77.139366 159.983551,77.021461 160.282440,76.729858 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M164.532562,76.484680 
	C164.554062,76.524460 164.511063,76.444893 164.532562,76.484680 
z"
              />
              <path
                fill="#C62C47"
                opacity="1.000000"
                stroke="none"
                d="
M97.130257,54.210785 
	C97.039703,54.075790 97.505997,54.041306 97.739288,54.051357 
	C97.972588,54.061409 97.654808,54.409840 97.654808,54.409840 
	C97.654808,54.409840 97.220810,54.345783 97.130257,54.210785 
z"
              />
              <path
                fill="#F9EDEE"
                opacity="1.000000"
                stroke="none"
                d="
M89.992828,75.271545 
	C90.198311,75.113365 90.464737,75.260498 90.831650,75.657806 
	C90.675278,75.732567 90.382492,75.622185 89.992828,75.271545 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M150.785370,73.028336 
	C150.991974,72.998253 150.917084,73.362488 150.867432,73.541740 
	C150.817764,73.720993 150.413651,73.691605 150.212494,73.667526 
	C150.114349,73.364883 150.303497,73.169876 150.785370,73.028336 
z"
              />
              <path
                fill="#DFA4AA"
                opacity="1.000000"
                stroke="none"
                d="
M116.147644,73.792168 
	C116.229492,73.571304 116.549156,73.449921 117.103676,73.454941 
	C117.020844,73.684776 116.703148,73.788208 116.147644,73.792168 
z"
              />
              <path
                fill="#E6E4E5"
                opacity="1.000000"
                stroke="none"
                d="
M32.660492,59.435753 
	C32.552765,59.372692 32.484230,59.582291 32.520317,59.606621 
	C32.556408,59.630951 32.768223,59.498810 32.660492,59.435753 
z"
              />
              <path
                fill="#DB949D"
                opacity="1.000000"
                stroke="none"
                d="
M65.915878,57.469460 
	C65.977699,57.802402 65.731209,57.961773 65.175774,57.967682 
	C65.132042,57.677341 65.378960,57.504562 65.915878,57.469460 
z"
              />
              <path
                fill="#E5B6BB"
                opacity="1.000000"
                stroke="none"
                d="
M156.984680,54.940731 
	C157.060791,55.233406 156.690704,55.160217 156.505371,55.125053 
	C156.264542,54.910397 156.209061,54.730900 156.056381,54.286880 
	C156.275650,54.230919 156.592102,54.439487 156.984680,54.940731 
z"
              />
              <path
                fill="#C5163D"
                opacity="1.000000"
                stroke="none"
                d="
M62.291824,158.083786 
	C64.057983,158.076935 65.383949,158.032913 66.707008,158.076767 
	C71.312683,158.229446 73.439377,160.576538 73.171112,165.050705 
	C73.102684,166.191971 73.082085,167.412186 73.404198,168.486816 
	C74.450882,171.978790 72.411415,172.889206 69.639931,173.170227 
	C68.673553,173.268234 67.675598,173.016998 66.689934,172.968643 
	C57.503338,172.517975 57.767918,172.491043 56.775696,163.630585 
	C56.280682,159.210098 58.909710,158.776474 62.291824,158.083786 
z"
              />
              <path
                fill="#BB0009"
                opacity="1.000000"
                stroke="none"
                d="
M147.080261,73.722839 
	C146.535797,74.019012 146.172913,74.069878 145.541260,74.188995 
	C144.896515,73.759628 144.520554,73.262016 144.088470,72.453064 
	C145.389297,72.085541 146.746277,72.029350 148.510406,71.998962 
	C148.365677,72.509010 147.813766,72.993271 147.080261,73.722839 
z"
              />
              <path
                fill="#DFA4AA"
                opacity="1.000000"
                stroke="none"
                d="
M138.072159,72.784378 
	C137.534927,72.921730 137.067673,72.892311 136.270996,72.741287 
	C136.520111,72.442284 137.098618,72.264893 137.977997,72.185127 
	C138.278854,72.282753 138.142120,72.617607 138.072159,72.784378 
z"
              />
              <path
                fill="#BB0009"
                opacity="1.000000"
                stroke="none"
                d="
M55.402000,61.641090 
	C55.779808,61.875740 55.836227,62.135727 55.919060,62.793869 
	C55.524891,63.137817 55.104317,63.083603 54.366699,63.002064 
	C54.393307,62.538635 54.736961,62.102531 55.402000,61.641090 
z"
              />
              <path
                fill="#C30124"
                opacity="1.000000"
                stroke="none"
                d="
M100.684906,50.223717 
	C100.151276,50.166683 99.952774,50.161106 99.459946,50.129810 
	C99.140877,49.575294 99.116135,49.046490 99.145836,48.120579 
	C99.609352,47.773899 100.018425,47.824329 100.738739,47.850739 
	C101.811783,48.190113 102.573578,48.553509 103.636482,49.016228 
	C103.790878,49.409298 103.644157,49.703053 103.268860,50.116119 
	C102.366859,50.248680 101.693443,50.261929 100.684906,50.223717 
z"
              />
              <path
                fill="#F2D9DC"
                opacity="1.000000"
                stroke="none"
                d="
M111.969040,45.713001 
	C112.038528,45.287182 112.187958,45.135319 112.656082,44.963867 
	C112.985008,45.331547 112.995232,45.718815 112.680359,46.287338 
	C112.253174,46.308052 112.151077,46.147507 111.969040,45.713001 
z"
              />
              <path
                fill="#F7E8EA"
                opacity="1.000000"
                stroke="none"
                d="
M154.691589,73.976791 
	C154.924713,74.441360 154.986832,74.897430 155.105133,75.699493 
	C154.664139,77.077255 154.166977,78.109024 153.302017,78.795975 
	C153.084793,77.214699 153.235352,75.978241 153.612152,74.339218 
	C154.067505,73.910454 154.294891,73.920998 154.691589,73.976791 
z"
              />
              <path
                fill="#F2D9DC"
                opacity="1.000000"
                stroke="none"
                d="
M113.343994,45.132103 
	C113.107452,44.691170 113.279282,44.387085 113.726578,44.007786 
	C113.918823,44.378029 113.835587,44.823490 113.343994,45.132103 
z"
              />
              <path
                fill="#BB0000"
                opacity="1.000000"
                stroke="none"
                d="
M159.940216,74.934303 
	C159.644913,75.075005 159.355148,74.878296 158.863892,74.508881 
	C158.898117,73.891907 159.133850,73.447647 159.663086,73.125473 
	C159.952972,73.697342 159.949371,74.147110 159.940216,74.934303 
z"
              />
            </svg>
          ),
          description1:
            "TorchKGE provides a clean and efficient API to design and test new models for KG embedding, a central application of KGs. It features a KG data structure, simple model interfaces, and modules for negative sampling and model evaluation.",
          description2:
            "TorchKGE is highly efficient in the link prediction task, which measures the ability of a model to predict missing facts in a KG. It has been observed to be up to five times faster than AmpliGraph and twenty-four times faster than OpenKE.",
          link: "https://torchkge.readthedocs.io",
        },
        {
          name: "Hyperswitch",
          iconBg: (
            <svg
              width="265"
              // height="265"
              // viewBox="0 0 265 265"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0.8"
            >
              <path
                d="M202.202 0H63.716C28.515 0 0 28.515 0 63.716C0 98.917 28.515 127.432 63.716 127.432H202.202C237.403 127.432 265.918 98.917 265.918 63.716C265.918 28.515 237.403 0 202.202 0Z"
                fill="#0070FF"
              />
              <path
                d="M208.482 83.863C219.72 83.863 229.068 74.515 229.068 63.277C229.068 52.039 219.72 42.691 208.482 42.691C197.244 42.691 187.896 52.039 187.896 63.277C187.896 74.515 197.244 83.863 208.482 83.863Z"
                fill="white"
              />
              <path
                d="M79.231 79.404L58.154 46.738C57.978 46.494 56.768 46.494 56.592 46.738L35.515 79.404C35.339 79.648 35.592 79.926 35.892 79.926H78.854C79.154 79.926 79.407 79.648 79.231 79.404Z"
                fill="white"
              />
              <path
                d="M149.541 45.079H116.082C115.682 45.079 115.332 45.429 115.332 45.829V80.288C115.332 80.688 115.682 81.038 116.082 81.038H149.541C149.941 81.038 150.291 80.688 150.291 80.288V45.829C150.291 45.429 149.941 45.079 149.541 45.079Z"
                fill="white"
              />
            </svg>
          ),
          description1:
            "Hyperswitch is a community-led, open payments switch aimed at facilitating access to diverse payments infrastructure for digital businesses. With support for over 50 payment processors and various global payment methods, it offers features like failover, smart routing, and customization.",
          description2:
            "Building on its robust capabilities, Hyperswitch is designed to empower businesses by providing them with unparalleled control over their payment processes. Its user-friendly interface and comprehensive analytics tools allow businesses to monitor transaction flows in real-time, enabling them to make data-driven decisions. Furthermore, Hyperswitch's commitment to security ensures that all transactions are conducted with utmost integrity, providing businesses with the confidence they need to grow in the digital economy. With its continuous innovation and community-driven approach, Hyperswitch is revolutionizing the way businesses handle payments.",
          link: "https://hyperswitch.io",
        },
      ],
    },
  };

  const [posX, setPosX] = useState(-1);
  const [posY, setPosY] = useState(-1);
  const [transformX, setTransformX] = useState(0);
  const [transformY, setTransformY] = useState(0);

  useEffect(() => {
    document.title = personalInformation.name.split(" ")[0] + " | Home";
    window.addEventListener("mousemove", (event) => {
      setPosX(event.clientX);
      setPosY(event.clientY);

      // Calculate transformX and transformY as the distance of mouse from the center of the screen and bring it down to the range of -10 to 10.

      setTransformX(
        -((event.clientX - window.innerWidth / 2) / window.innerWidth) * 20
      );
      setTransformY(
        -((event.clientY - window.innerHeight / 2) / window.innerHeight) * 20
      );
    });
  }, []);

  return (
    <div className="section" style={{ textAlign: "left" }}>
      <header className="page-header">
        <div className="page-header__inr">
          <div
            className="page-header__content is-onscreen"
            data-prlx="0 0.25"
            style={{ position: "relative" }}
          >
            <div className="article-heading">
              <h1 className="article-heading__title t-heading t-medium:s t-bigger:m t-biggest:l">
                <span>
                  Hi, my name is <b>{personalInformation.name.split(" ")[0]}</b>
                  <span className="dot">.</span>
                </span>
              </h1>
            </div>
            {personalInformation.description}
            <span className="page-header__scroll" aria-hidden="true">
              <span>
                <span>S</span> <span>c</span> <span>r</span> <span>o</span>{" "}
                <span>l</span> <span>l</span>
              </span>
            </span>
          </div>
        </div>
      </header>

      <div className="approach panels__main" style={{ textAlign: "left" }}>
        <h2 class="article-heading__title t-heading t-medium:s t-big:m t-bigger:l">
          <span>
            Let's work <b>together</b>
            <span class="dot">.</span>
          </span>
        </h2>
        {personalInformation.letsWorkTogether.description}
        <a href="/work" class="btn">
          <span class="btn__label">About my approach</span>{" "}
          <span
            class="btn__fill stripes"
            data-drift="-10 -12"
            aria-hidden="true"
          >
            About my approach
          </span>
        </a>
      </div>

      <div className="open-source" style={{ textAlign: "left" }}>
        <div class="dot-header__content flow">
          <h2 class="t-heading t-lede:s t-medium:m t-medium:l">
            Open Source<span class="dot">.</span>
          </h2>
          <p>{personalInformation.openSource.description}</p>
        </div>
      </div>
      {personalInformation.openSource.projects.map((project, index) => {
        // console.log(`translate(${transformX}, ${transformY})`);
        return (
          <div class="project-promo__item project">
            <div class="project__header">
              <div class="illustration">
                <div
                  class="illustration__layer"
                  data-drift="0 0"
                  style={{
                    transform: `translate(${transformX}px, ${transformY}px)`,
                  }}
                >
                  {project.iconBg}
                </div>
                <div class="illustration__layer">{project.iconFill}</div>
              </div>
            </div>
            <div class="project__details">
              <div class="flow">
                <h3 class="t-heading t-lede:s t-medium:m t-medium:l">
                  {project.name}
                  <span class="dot">.</span>
                </h3>
                <p>{project.description1}</p>
                <p>{project.description2}</p>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noreferrer"
                  class="btn"
                >
                  <span class="btn__label">Get {project.name}</span>{" "}
                  <span
                    class="btn__fill stripes"
                    data-drift="-10 -12"
                    aria-hidden="true"
                  >
                    Get {project.name}
                  </span>
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default LandingPage;
